import {
  Auth,
  Firestore,
  GetDocFromReference,
  UsersManagementDoc,
} from '@/firebase-exports'
import { clientsSubscription } from '@/services/clients-service'
import { getParentAttributes } from '@/services/users-service'
import { listHistorySubscription } from '@/services/history-service'

import getUserPages from '@/utils/get-user-pages'
import router from '@/router'
import i18n from '@/i18n'
import { getBilling } from '@/services/billing-service'
import { newsSubscription } from '@/services/news-service'
import { getLegal } from '@/services/legal-service'

const actions = {
  async fetchUser({ dispatch, commit }) {
    try {
      commit('setLoading', true)
      const firebaseUser = await Auth.getAuth().currentUser
      Auth.getAuth().currentUser.getIdToken(false)
      if (firebaseUser) {
        var userUnsubscribe = Firestore.onSnapshot(
          UsersManagementDoc(firebaseUser.uid),
          async (docSnap) => {
            if (!docSnap.exists()) {
              // Usuario no existe en Management
              commit('setSnackbar', {
                position: 'top',
                type: 'error',
                message: i18n.t('unauthorized'),
              })
              dispatch('logoutUnauthorized')
            } else {
              var firestoreUser = docSnap.data()
              let parentAttributes = {}

              if (firestoreUser.parentRef)
                parentAttributes = await getParentAttributes(
                  firestoreUser.parentRef.id
                )

              var firestoreCopy = {
                ...firestoreUser,
                id: firebaseUser.uid,
                allNotifications: false,
                ...parentAttributes,
              }

              if (firestoreCopy.addaliaRef)
                firestoreCopy.addalia = await GetDocFromReference(
                  firestoreCopy.addaliaRef
                )

              dispatch('configureUser', firestoreCopy)

              // Suscripción de los  clientes
              await clientsSubscription()

              // Suscripción de las noticias
              await newsSubscription()

              // Suscripción del historial
              await listHistorySubscription(
                firestoreUser.parentRef
                  ? firestoreUser.parentRef.id
                  : firebaseUser.uid
              )

              const tokenExpiration =
                firebaseUser.stsTokenManager.expirationTime
              const currentDate = new Date().getTime()
              const expirationTimeout = tokenExpiration - currentDate
              setTimeout(async () => {
                await dispatch('fetchUser')
              }, expirationTimeout)
              if (!router.currentRoute.meta.userCanAccess()) {
                router.push('/')
              }
            }
          },
          (error) => {
            throw error
          }
        )
        commit('setLoading', false)
        return userUnsubscribe
      } else {
        throw new Error('User is not authenticated')
      }
    } catch (err) {
      dispatch('configureUser', null)
      commit('setLoading', false)
      throw err
    }
  },
  configureUser({ commit }, userData) {
    commit('setUser', userData)

    if (!userData) commit('setNavigationPages', {})
    else {
      var userPages = getUserPages()
      commit('setNavigationPages', userPages)
    }
  },
  async logout({ dispatch }) {
    router.replace('/login')
    dispatch('killListeners')
    dispatch('resetStore')
    await Auth.signOut(Auth.getAuth())
    dispatch('configureUser', null)
    window.location.reload()
  },
  async logoutUnauthorized() {
    await Auth.signOut(Auth.getAuth())
    setTimeout(() => {
      window.location.reload()
    }, 1000)
  },
  async getBilling({ commit }) {
    const billing = await getBilling()

    if (!billing) {
      commit('setSnackbar', {
        position: 'top',
        type: 'error',
        message: this.$t('errorLoadingPackages'),
      })
      return //TODO: Ver si hacer login o marcar flag para bloquear ciertas acciones
    }
    // Cargamos en en store billing
    commit('setAppBilling', {
      // Paquetes EMPRESA
      empresaAppCuota: billing.empresaAppCuota,
      empresaNotificacionesCif: billing.empresaNotificacionesCif,
      empresaNotificacionesPaquetesCuota:
        billing.empresaNotificacionesPaquetesCuota,
      empresaNotificacionesPaquetes:
        billing.empresaNotificacionesPaquetesCuota.map((item) => item.amount),
      empresaFirmasCertificadosCuota: billing.empresaFirmasCertificadosCuota,
      empresaFirmasCertificados: billing.empresaFirmasCertificadosCuota.map(
        (item) => item.amount
      ),
      empresaFirmasPaquetesCuota: billing.empresaFirmasPaquetesCuota,
      empresaFirmasPaquetes: billing.empresaFirmasPaquetesCuota.map(
        (item) => item.amount
      ),
      empresa2FACuota: billing.empresa2FACuota,
      empresaBrandCuota: billing.empresaBrandCuota,
      //Paquetes DESPACHO
      despachoAppCuota: billing.despachoAppCuota,
      despachoNotificacionesCif: billing.despachoNotificacionesCif,
      despachoNotificacionesPaquetesCuota:
        billing.despachoNotificacionesPaquetesCuota,
      despachoNotificacionesPaquetes:
        billing.despachoNotificacionesPaquetesCuota.map((item) => item.amount),
      despachoFirmasCertificadosCuota: billing.despachoFirmasCertificadosCuota,
      despachoFirmasCertificados: billing.despachoFirmasCertificadosCuota.map(
        (item) => item.amount
      ),
      despachoFirmasPaquetesCuota: billing.despachoFirmasPaquetesCuota,
      despachoFirmasPaquetes: billing.despachoFirmasPaquetesCuota.map(
        (item) => item.amount
      ),
      despacho2FACuota: billing.despacho2FACuota,
      despachoBrandCuota: billing.despachoBrandCuota,
      // Paquetes Tokens MAYORISTA
      mayoristaTokenPaquetesCuota: billing.mayoristaTokenPaquetesCuota,
      mayoristaTokenPaquetes: billing.mayoristaTokenPaquetesCuota.map(
        (item) => item.amount
      ), //para simplificar el algoritmo de restarNumerosHastaMenor
      // Lista de correo de Gestión
      aManagementMailingList: billing.aManagementMailingList,
      // numero de licencias usadas por cada CIF gran destinatario de un Despacho
      consumoLicenciasCifGD: billing.consumoLicenciasCifGD
        ? billing.consumoLicenciasCifGD
        : 20,
    })
  },
  async getLegal({ commit }) {
    const legal = await getLegal()

    if (!legal) {
      commit('setSnackbar', {
        position: 'top',
        type: 'error',
        message: this.$t('errorLoadingLegal'),
      })
      return //TODO: Ver si hacer login o marcar flag para bloquear ciertas acciones
    }
    // Cargamos en el store el documento legal
    commit('setAppLegal', legal?.contractModel)
  },
  killListeners({ commit }) {
    commit('killListenersMutation')
  },
  resetStore({ commit }) {
    commit('resetState')
  },
}
export default actions
