<template>
  <v-card class="fill-height" v-if="client" outlined>
    <v-card-title>
      <v-icon left>mdi-domain</v-icon>
      {{ $t('client.clientDetail.servicesPurchased') }}
      <v-spacer></v-spacer>
      <div class="mr-3 body-1" v-if="clientProp.plan">
        {{ $t('client.clientDetail.expirationLicense') }}:
        <span
          :class="{
            'expirated-date':
              clientProp.plan.expirationLicense.toDate() <= new Date(),
          }"
        >
          {{ $d(clientProp.plan.expirationLicense.toDate()) }}
        </span>
      </div>
      <v-chip
        class="white--text"
        :color="getClientStatus(clientProp).color"
        id="enter-notif-detail"
      >
        {{ $t(getClientStatus(clientProp).status) }}
      </v-chip>

      <v-chip
        v-if="clientProp.trialLicense"
        class="ma-2 white--text"
        color="orange"
      >
        {{ $t('trialLicenseChip') }}
      </v-chip>
    </v-card-title>

    <v-card-text>
      <v-row justify="space-between" align="center">
        <v-col cols="12">
          <div class="text-start">
            <v-row align="center" v-if="clientProp.userActivated">
              <v-col v-if="isAddaliaAdminUser" cols="4" class="mt-2">
                <v-switch
                  v-model="toggleSwitch"
                  @change="dialogActivate = true"
                  :label="$t('client.clientDetail.userActive')"
                  class="body-1 text-end pa-0 ma-0"
                  id="activate-client"
                >
                </v-switch>
              </v-col>

              <!-- estado de renovacion de la licencia -->
              <v-row
                v-if="
                  isAddaliaUser &&
                  clientProp.estadoRenovacionLicencia &&
                  clientProp.estadoRenovacionLicencia != 'renovacionOK' &&
                  this.allowToPerformAction('changeLicenseRenewalStatus')
                "
              >
                <v-col cols="8" class="ml-3">
                  <v-select
                    :items="licenseRenewalStatusList"
                    :label="$t('renewalStatusChange')"
                    v-model="client.estadoRenovacionLicencia"
                    :disabled="clientProp.estado !== 'activo'"
                  >
                    <template v-slot:item="{ item }">
                      {{ $t('clientRenewalStatus.' + item) }}
                    </template>
                    <template v-slot:selection="{ item }">
                      {{ $t('clientRenewalStatus.' + item) }}
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="3" class="mt-3">
                  <v-btn
                    @click="updateLicenseRenewalStatus()"
                    text
                    color="primary"
                    :loading="confirmLoading"
                    :disabled="!licenseRenewalStatusChanges || confirmLoading"
                  >
                    {{ $t('client.clientDetail.save') }}
                  </v-btn>
                </v-col>
              </v-row>
              <v-row
                v-else-if="
                  clientProp.estadoRenovacionLicencia &&
                  clientProp.estadoRenovacionLicencia != 'renovacionOK'
                "
              >
                <!-- label informativo para aquellos que no pueden cambiar el estado -->
                <v-col cols="10" class="mt-3 ml-6 mb-3">
                  <span class="body-1">
                    <b>{{ $t('renewalStatus') }}: </b>
                  </span>
                  <span class="body-1 text-end">
                    {{
                      $t(
                        `clientRenewalStatus.${clientProp.estadoRenovacionLicencia}`
                      )
                    }}
                  </span>
                </v-col>
              </v-row>
            </v-row>
            <v-row
              v-else-if="
                !clientProp.userActivated &&
                isAddaliaUser &&
                allowToPerformAction('changeUserStatus')
              "
            >
              <v-col cols="9">
                <v-select
                  :items="userStatusList"
                  :label="$t('statusChange')"
                  v-model="client.estado"
                  :disabled="!currentStateValidForCurrentRol"
                >
                  <template v-slot:item="{ item }">
                    {{ $t('clientStatus.' + item) }}
                  </template>
                  <template v-slot:selection="{ item }">
                    {{ $t('clientStatus.' + item) }}
                  </template>
                </v-select>
              </v-col>
              <v-col cols="3" class="mt-3">
                <v-btn
                  @click="updateStatus()"
                  text
                  color="primary"
                  :loading="confirmLoading"
                  :disabled="!statusChanges || confirmLoading"
                >
                  {{ $t('client.clientDetail.save') }}
                </v-btn>
              </v-col>
            </v-row>

            <v-divider
              class="mb-3"
              v-if="
                !clientProp.userActivated &&
                isAddaliaUser &&
                allowToPerformAction('changeUserStatus')
              "
            />

            <v-col class="mt-3" cols="auto">
              <h2>
                {{ $t('client.clientDetail.notificationsActive') }}
                <v-icon v-if="clientProp.notificacionesActivasEmpresa">
                  mdi-check
                </v-icon>
                <v-icon v-else> mdi-close </v-icon>
              </h2>
            </v-col>

            <v-col cols="12" v-if="clientProp.userActivated">
              <v-row justify="space-between">
                <v-col cols="3">
                  <h3>{{ $t('cifsNotifications') }}</h3>
                  <v-tooltip v-if="notificationsCuotaPending" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on">mdi-alert</v-icon>
                    </template>
                    <span>{{ $t('pendingActivationPackages') }}</span>
                  </v-tooltip>
                </v-col>
                <v-col v-if="clientProp.plan" cols="8">
                  <AppPlanProgress
                    :current="cifsCountNotifications"
                    :max="clientProp.plan.notificationsMaxCifs"
                    :disabled="!clientProp.notificacionesActivasEmpresa"
                    textSize="h1"
                    icon="mdi-city"
                  />
                </v-col>
                <v-col cols="auto">
                  <UpgradeCuotaDialog
                    v-if="clientProp.tipoEmpresa != 'ASESORIACOLECTIVO'"
                    :disabled="
                      !clientProp.userActivated ||
                      !allowToPerformAction('createInvoice') ||
                      notificationsCuotaPending ||
                      renewalPending
                    "
                    :client="clientProp"
                    :cuotaServicio="
                      clientProp.tipoEmpresa == 'EMPRESA'
                        ? this.$store.state.appBilling.empresaNotificacionesCif
                        : this.$store.state.appBilling.despachoNotificacionesCif
                    "
                    :typeProp="
                      clientProp.notificacionesActivas
                        ? 'upgrade'
                        : 'activation'
                    "
                    assetName="notificationsCuota"
                  >
                  </UpgradeCuotaDialog>
                  <UpgradeCuotaCollectiveDialog
                    :clientProp="client"
                    v-else
                  ></UpgradeCuotaCollectiveDialog>
                </v-col>
              </v-row>
              <v-row justify="space-between">
                <v-col cols="3">
                  <h3>{{ $t('packagesNotifications') }}</h3>
                  <v-tooltip v-if="notificationsPackagesPending" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on">mdi-alert</v-icon>
                    </template>
                    <span>{{ $t('pendingActivationPackages') }}</span>
                  </v-tooltip>
                </v-col>
                <v-col v-if="clientProp.plan" cols="8">
                  <AppPlanProgress
                    :current="clientProp.plan.notificationsUsed"
                    :max="clientProp.plan.notificationsMax"
                    :disabled="!clientProp.notificacionesActivasEmpresa"
                    textSize="h1"
                    icon="mdi-mailbox"
                  />
                </v-col>
                <v-col cols="auto">
                  <UpgradePackagesDialog
                    v-if="clientProp.tipoEmpresa != 'ASESORIACOLECTIVO'"
                    :disabled="
                      !clientProp.userActivated ||
                      !allowToPerformAction('createInvoice') ||
                      notificationsPackagesPending ||
                      renewalPending
                    "
                    :client="clientProp"
                    :prices="
                      clientProp.tipoEmpresa == 'EMPRESA'
                        ? $store.state.appBilling
                            .empresaNotificacionesPaquetesCuota
                        : $store.state.appBilling
                            .despachoNotificacionesPaquetesCuota
                    "
                    :packagesList="
                      clientProp.tipoEmpresa == 'EMPRESA'
                        ? $store.state.appBilling.empresaNotificacionesPaquetes
                        : $store.state.appBilling.despachoNotificacionesPaquetes
                    "
                    assetName="notificationsPackages"
                    :label="$t('numberOfNotifications')"
                    :typeProp="
                      clientProp.notificacionesActivas
                        ? 'upgrade'
                        : 'activation'
                    "
                  >
                  </UpgradePackagesDialog>
                  <UpgradeCuotaCollectiveDialog
                    :clientProp="client"
                    v-else
                  ></UpgradeCuotaCollectiveDialog>
                </v-col>
              </v-row>
            </v-col>
            <br />
            <v-col
              v-if="clientProp.tipoEmpresa != 'ASESORIACOLECTIVO'"
              cols="auto"
            >
              <h2>
                {{ $t('client.clientDetail.signaturesActive') }}
                <v-icon v-if="clientProp.firmasActivasEmpresa">
                  mdi-check
                </v-icon>
                <v-icon v-else> mdi-close </v-icon>
              </h2>
            </v-col>

            <v-col
              cols="12"
              v-if="
                clientProp.userActivated &&
                clientProp.tipoEmpresa != 'ASESORIACOLECTIVO'
              "
            >
              <v-row justify="space-between" align="center">
                <v-col cols="3">
                  <h3>{{ $t('certificatesSignatures') }}</h3>
                  <v-tooltip v-if="signaturesCertsPackagesPending" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on">mdi-alert</v-icon>
                    </template>
                    <span>{{ $t('pendingActivationPackages') }}</span>
                  </v-tooltip>
                </v-col>
                <v-col v-if="clientProp.plan" cols="8">
                  <AppPlanProgress
                    :current="certsCountSignatures"
                    :max="clientProp.plan.signaturesMaxCerts"
                    :disabled="!clientProp.firmasActivasEmpresa"
                    textSize="h1"
                    icon="mdi-account-key"
                  />
                </v-col>
                <v-col cols="auto">
                  <UpgradePackagesDialog
                    :disabled="
                      !clientProp.userActivated ||
                      !allowToPerformAction('createInvoice') ||
                      signaturesCertsPackagesPending ||
                      renewalPending
                    "
                    :client="clientProp"
                    :prices="
                      clientProp.tipoEmpresa == 'EMPRESA'
                        ? $store.state.appBilling.empresaFirmasCertificadosCuota
                        : $store.state.appBilling
                            .despachoFirmasCertificadosCuota
                    "
                    :packagesList="
                      clientProp.tipoEmpresa == 'EMPRESA'
                        ? $store.state.appBilling.empresaFirmasCertificados
                        : $store.state.appBilling.despachoFirmasCertificados
                    "
                    assetName="signaturesCertsPackages"
                    :label="$t('numberCertificates')"
                    :typeProp="
                      clientProp.firmasActivas ? 'upgrade' : 'activation'
                    "
                  >
                  </UpgradePackagesDialog>
                </v-col>
              </v-row>
              <v-row justify="space-between">
                <v-col cols="3">
                  <h3>{{ $t('packagesSignatures') }}</h3>
                  <v-tooltip v-if="signaturesPackagesPending" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on">mdi-alert</v-icon>
                    </template>
                    <span>{{ $t('pendingActivationPackages') }}</span>
                  </v-tooltip>
                </v-col>
                <v-col v-if="clientProp.plan" cols="8">
                  <AppPlanProgress
                    :current="clientProp.plan.signaturesUsed"
                    :max="clientProp.plan.signaturesMax"
                    :disabled="!clientProp.firmasActivasEmpresa"
                    textSize="h1"
                    icon="mdi-draw"
                  />
                </v-col>
                <v-col cols="auto">
                  <UpgradePackagesDialog
                    :disabled="
                      !clientProp.userActivated ||
                      !allowToPerformAction('createInvoice') ||
                      signaturesPackagesPending ||
                      renewalPending
                    "
                    :client="clientProp"
                    :prices="
                      clientProp.tipoEmpresa == 'EMPRESA'
                        ? $store.state.appBilling.empresaFirmasPaquetesCuota
                        : $store.state.appBilling.despachoFirmasPaquetesCuota
                    "
                    :packagesList="
                      clientProp.tipoEmpresa == 'EMPRESA'
                        ? $store.state.appBilling.empresaFirmasPaquetes
                        : $store.state.appBilling.despachoFirmasPaquetes
                    "
                    assetName="signaturesPackages"
                    :label="$t('numberOfSignatures')"
                    :typeProp="
                      clientProp.firmasActivas ? 'upgrade' : 'activation'
                    "
                  >
                  </UpgradePackagesDialog>
                </v-col>
              </v-row>
            </v-col>
            <v-row v-if="clientProp.tipoEmpresa != 'ASESORIACOLECTIVO'">
              <v-col cols="6">
                <v-col class="mt-3" cols="auto">
                  <h2>{{ $t('client.clientDetail.additionalServices') }}:</h2>
                </v-col>

                <v-col cols="auto">
                  <h3>
                    {{ $t('client.clientDetail.multiFactor') }}
                    <v-icon v-if="clientProp.allowed2FA"> mdi-check </v-icon>
                    <v-icon v-else> mdi-close </v-icon>
                  </h3>
                </v-col>

                <v-col
                  class="pb-0 pt-0"
                  cols="8"
                  v-if="
                    clientProp.allowed2FA &&
                    allowToPerformAction('changeClientInformation')
                  "
                >
                  <v-btn
                    text
                    @click="openSetupDialog('setup2FA', clientProp.multiFactor)"
                    color="primary"
                  >
                    {{ $t('client.clientDetail.setup2FA') }}
                  </v-btn>
                </v-col>

                <v-col cols="auto">
                  <h3>
                    {{ $t('client.clientDetail.customBrand') }}
                    <v-icon v-if="clientProp.allowedCustomBrand">
                      mdi-check
                    </v-icon>
                    <v-icon v-else> mdi-close </v-icon>
                  </h3>
                </v-col>

                <v-col
                  class="pb-0 pt-0"
                  cols="8"
                  v-if="
                    clientProp.allowedCustomBrand &&
                    allowToPerformAction('changeClientInformation')
                  "
                >
                  <v-btn
                    text
                    @click="
                      openSetupDialog(
                        'setupCustomBrand',
                        clientProp.customBrand
                      )
                    "
                    color="primary"
                  >
                    {{ $t('client.clientDetail.setupCustomBrand') }}
                  </v-btn>
                </v-col>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <ClientRenew
        v-if="
          clientProp.userActivated &&
          allowToPerformAction('createRenew') &&
          !renewalPending &&
          !upgradesPending &&
          clientProp.tipoEmpresa != 'ASESORIACOLECTIVO'
        "
        :minimumSignaturesCifs="certsCountSignatures"
        :minimumNotificationsCifs="cifsCountNotifications"
        :client="clientProp"
        typeProp="renewal"
      >
      </ClientRenew>

      <v-dialog v-model="dialogActivate" width="500" persistent>
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            <v-container>
              <v-row justify="space-between" align="center">
                <span v-if="clientProp.disabled">
                  {{ $t('confirmActivateTitle') }}
                </span>
                <span v-else>
                  {{ $t('confirmDeactivateTitle') }}
                </span>
                <v-btn
                  fab
                  @click="handleCloseDialog"
                  small
                  plain
                  :disabled="confirmLoading"
                >
                  <v-icon> mdi-close </v-icon>
                </v-btn>
              </v-row>
            </v-container>
          </v-card-title>
          <br />
          <v-card-text v-if="clientProp.disabled">
            {{ $t('confirmActivateText') }}
            <div
              class="noteBoxes typeOrange"
              style="display: flex"
              v-if="
                isAddaliaUser &&
                (!clientProp.redmineKey || !clientProp.redmineProject)
              "
            >
              <img src="@/assets/alert.png" class="note-box-icon" />
              <div>{{ $t('redmineMissing') }}</div>
            </div>
          </v-card-text>
          <v-card-text v-else>
            {{ $t('confirmDeactivateText') }}
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              v-if="clientProp.disabled"
              @click="confirmActivation()"
              color="primary"
              text
              id="confirm-activate-client"
              :loading="confirmLoading"
              :disabled="confirmLoading"
            >
              {{ $t('confirmActivateAcceptance') }}
            </v-btn>
            <v-btn
              v-else
              @click="deactivateUser()"
              color="primary"
              text
              id="confirm-deactivate-client"
              :loading="confirmLoading"
              :disabled="confirmLoading"
            >
              {{ $t('confirmActivateAcceptance') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-actions>
    <SetupDialog
      :userId="clientProp.id"
      :action="setupAction"
      :args="setupArgs"
      @closeSetupDialog="closeSetupDialog"
      v-if="setupDialog"
    />
  </v-card>
</template>

<script>
import {
  getClientStatus,
  createEmpresaDMS,
  userDisable,
  updateClient,
} from '@/services/clients-service'
import { allowToPerformAction } from '@/services/users-service'
import getErrorText from '@/utils/get-error-text'
import AppPlanProgress from '@/components/commons/AppPlanProgress'
import UpgradePackagesDialog from '@/components/client/detail/actions/UpgradePackagesDialog'
import UpgradeCuotaDialog from '@/components/client/detail/actions/UpgradeCuotaDialog'
import ClientRenew from '@/components/client/detail/actions/ClientRenew'
import SetupDialog from '@/components/client/detail/actions/SetupDialog'
import UpgradeCuotaCollectiveDialog from '@/components/collective/UpgradeCuotaCollectiveDialog'

const lodash = require('lodash')
import rules from '@/utils/rules'
import { mapMutations, mapGetters } from 'vuex'
export default {
  props: {
    clientProp: {
      type: Object,
      required: true,
    },
    invoices: {
      type: Array,
      default() {
        return []
      },
    },
    cifs: {
      type: Array,
      default() {
        return []
      },
    },
  },
  components: {
    AppPlanProgress,
    UpgradePackagesDialog,
    UpgradeCuotaDialog,
    ClientRenew,
    SetupDialog,
    UpgradeCuotaCollectiveDialog,
  },
  async mounted() {
    if (
      !this.isAddaliaAdminUser &&
      this.isCommercialUser &&
      this.client.estado === 'pendienteComercial'
    )
      this.userStatusList = ['pendienteComercial', 'pendienteFirma']
    else if (
      !this.isAddaliaAdminUser &&
      this.isBillingUser &&
      this.client.estado === 'pendienteFirma'
    )
      this.userStatusList = ['pendienteFirma', 'pendienteFacturacion']
    else if (
      !this.isAddaliaAdminUser &&
      this.isBillingUser &&
      this.client.estado === 'pendienteFacturacion'
    )
      this.userStatusList = ['pendienteFacturacion', 'pendienteActivacion']
    else if (
      !this.isAddaliaAdminUser &&
      this.isSupportUser &&
      this.client.estado === 'pendienteActivacion'
    )
      this.userStatusList = ['pendienteActivacion', 'activo']
    else if (
      this.isAddaliaAdminUser &&
      this.client.trialLicense &&
      this.client.estado === 'pendienteActivacion'
    )
      this.userStatusList = ['pendienteActivacion', 'activo']
    else
      this.userStatusList = [
        'pendienteComercial',
        'pendienteFirma',
        'pendienteFacturacion',
        'pendienteActivacion',
        'activo',
      ]

    this.reloadLicenseRenewalStatusList(
      this.clientProp.estadoRenovacionLicencia
    )
    this.consumoLicenciasCifGD =
      this.$store.state.appBilling.consumoLicenciasCifGD
  },
  data() {
    return {
      client: { ...this.clientProp },
      customBrand: false,
      phone2FA: undefined,
      userStatusList: [
        'pendienteFirma',
        'pendienteFacturacion',
        'pendienteActivacion',
        'activo',
      ],
      dialogActivate: false,
      // SetupDialog variables
      setupDialog: false,
      setupArgs: undefined,
      setupAction: '',
      // Evitamos valores undefined
      toggleSwitch: !this.clientProp.disabled || false,
      confirmLoading: false,
      licenseRenewalStatusList: [
        'pendienteRespuestaRenovacion',
        'enProcesoRenovacion',
        'noRenueva',
      ],
      consumoLicenciasCifGD: 0,
    }
  },
  computed: {
    ...mapGetters([
      'isAddaliaUser',
      'isCommercialUser',
      'isSupportUser',
      'isBillingUser',
      'isAddaliaAdminUser',
    ]),
    statusChanges() {
      // Observa cambios en la variable objetoString y devuelve true si ha cambiado
      return !lodash.isEqual(this.client.estado, this.clientProp.estado)
    },
    cifsCountNotifications() {
      if (this.client.tipoEmpresa === 'ASESORIA') {
        return this.cifs.reduce((acc, cif) => {
          return (
            acc +
            (cif.notificacionesActivas
              ? cif.granDestinatario
                ? this.consumoLicenciasCifGD
                : 1
              : 0)
          )
        }, 0)
      } else {
        return this.cifs.filter((c) => c.notificacionesActivas).length
      }
    },
    certsCountSignatures() {
      return this.cifs.reduce(
        (acc, cif) =>
          acc + (cif.allowedToSign ? Object.keys(cif.allowedToSign).length : 0),
        0
      )
    },
    currentStateValidForCurrentRol() {
      // The current state must be one in the list
      if (this.isCommercialUser)
        return (
          this.clientProp.estado === 'pendienteComercial' //||
          //this.clientProp.estado === 'pendienteFacturacion'
        )
      else if (this.isBillingUser)
        return (
          this.clientProp.estado === 'pendienteFirma' ||
          this.clientProp.estado === 'pendienteFacturacion' //||
          //this.clientProp.estado === 'pendienteActivacion'
        )
      else if (this.isSupportUser)
        return (
          this.clientProp.estado === 'pendienteActivacion' ||
          this.clientProp.estado === 'activo'
        )

      return true
    },
    renewalPending() {
      return this.invoices.some((objeto) => {
        return (
          objeto.type === 'renewal' &&
          objeto.status != 'active' &&
          !objeto.archived
        )
      })
    },
    upgradesPending() {
      return this.invoices.some((objeto) => {
        return (
          objeto.type === 'upgrade' &&
          objeto.status != 'active' &&
          !objeto.archived
        )
      })
    },
    notificationsPackagesPending() {
      return this.invoices.some((objeto) => {
        return (
          objeto.concept === 'notificationsPackages' &&
          objeto.status != 'active' &&
          !objeto.archived
        )
      })
    },
    signaturesPackagesPending() {
      return this.invoices.some((objeto) => {
        return (
          objeto.concept === 'signaturesPackages' &&
          objeto.status != 'active' &&
          !objeto.archived
        )
      })
    },
    signaturesCertsPackagesPending() {
      return this.invoices.some((objeto) => {
        return (
          objeto.concept === 'signaturesCertsPackages' &&
          objeto.status != 'active' &&
          !objeto.archived
        )
      })
    },
    notificationsCuotaPending() {
      return this.invoices.some((objeto) => {
        return (
          objeto.concept === 'notificationsCuota' &&
          objeto.status != 'active' &&
          !objeto.archived
        )
      })
    },
    licenseRenewalStatusChanges() {
      // Observa cambios en la variable y devuelve true si ha cambiado
      return !lodash.isEqual(
        this.client.estadoRenovacionLicencia,
        this.clientProp.estadoRenovacionLicencia
      )
    },
  },
  watch: {
    clientProp: function (newValue) {
      if (newValue.userActivated) this.dialogActivate = false
    },
  },
  methods: {
    ...mapMutations(['setSnackbar', 'setLoading']),
    ...rules,
    allowToPerformAction(action) {
      return allowToPerformAction(this.$store.state.user.rol, action)
    },
    async confirmActivation() {
      this.confirmLoading = true
      try {
        if (!this.clientProp.userActivated)
          await createEmpresaDMS({ userId: this.client.id })
        else await userDisable(this.client.id, false)

        this.dialogActivate = false
        this.toggleSwitch = true
        // Mostramos snackbar con el mensaje de exito
        const message = this.$t('client.clientDetail.activatedSuccess')
        this.setSnackbar({ position: 'top', type: 'success', message })
      } catch (error) {
        this.handleCloseDialog()
        // Mostramos snackbar con el mensaje de error
        const message = getErrorText(error.message)
        this.setSnackbar({ position: 'top', type: 'error', message })
      }
      this.confirmLoading = false
    },
    async deactivateUser() {
      this.confirmLoading = true
      try {
        // Marcamos disabled a false en Firestore
        await userDisable(this.client.id, true)

        this.dialogActivate = false
        this.toggleSwitch = false
        // Mostramos snackbar con el mensaje de exito
        const message = this.$t('client.clientDetail.deactivatedSuccess')
        this.setSnackbar({ position: 'top', type: 'success', message })
      } catch (error) {
        this.handleCloseDialog()
        // Mostramos snackbar con el mensaje de error
        const message = getErrorText(error.message)
        this.setSnackbar({ position: 'top', type: 'error', message })
      }
      this.confirmLoading = false
    },
    async updateStatus() {
      if (this.client.estado === 'activo') {
        if (this.clientProp.estado !== 'pendienteActivacion') {
          const message = this.$t('client.clientDetail.needPendingActivation')
          this.setSnackbar({ position: 'top', type: 'error', message })
        } else {
          this.dialogActivate = true
        }
      } else {
        this.confirmLoading = true
        try {
          await updateClient(this.client.id, {
            estado: this.client.estado,
          })
          // Mostramos snackbar con el mensaje de exito
          const message = this.$t('client.clientDetail.statusChangeSuccess')
          this.setSnackbar({ position: 'top', type: 'success', message })
        } catch (error) {
          // Mostramos snackbar con el mensaje de error
          const message = this.$t('client.clientDetail.statusChangeError')
          this.setSnackbar({ position: 'top', type: 'error', message })
        }
        this.confirmLoading = false
      }
    },
    async handleCloseDialog() {
      // Cerramos dialogo y volvemos al valor anterior del toggle
      this.dialogActivate = false
      this.toggleSwitch = !this.toggleSwitch
    },
    openSetupDialog(action, args) {
      this.setupAction = action
      this.setupArgs = args
      this.setupDialog = true
    },
    closeSetupDialog() {
      this.setupDialog = false
      this.setupAction = ''
      this.setupArgs = undefined
    },
    getClientStatus(client) {
      return getClientStatus(client)
    },
    async updateLicenseRenewalStatus() {
      if (this.client.estado === 'activo') {
        this.confirmLoading = true
        try {
          await updateClient(this.client.id, {
            estadoRenovacionLicencia: this.client.estadoRenovacionLicencia,
          })
          // recarga el select con lo valores apropiados
          this.reloadLicenseRenewalStatusList(
            this.client.estadoRenovacionLicencia
          )
          // Mostramos snackbar con el mensaje de exito
          const message = this.$t('client.clientDetail.statusChangeSuccess')
          this.setSnackbar({ position: 'top', type: 'success', message })
        } catch (error) {
          // Mostramos snackbar con el mensaje de error
          const message = this.$t('client.clientDetail.statusChangeError')
          this.setSnackbar({ position: 'top', type: 'error', message })
        }
        this.confirmLoading = false
      }
    },
    reloadLicenseRenewalStatusList(estadoRenovacionLicencia) {
      switch (estadoRenovacionLicencia) {
        case 'pendienteRespuestaRenovacion':
          this.licenseRenewalStatusList = [
            'pendienteRespuestaRenovacion',
            'enProcesoRenovacion',
            'noRenueva',
          ]
          break

        case 'enProcesoRenovacion':
        case 'noRenueva':
          this.licenseRenewalStatusList = ['enProcesoRenovacion', 'noRenueva']
          break

        case 'renovacionOK':
          this.licenseRenewalStatusList = ['renovacionOK']
          break
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.noteBoxes {
  border: 1px solid;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0;
  width: 100%;
}

.typeOrange {
  border-color: #c79800;
  background-color: rgba(255, 111, 0, 0.1);
}

.note-box-icon {
  width: 40px;
  height: 30px;
  padding-right: 10px;
}

.expirated-date {
  /*background-color: red;*/
  background-color: #d70a0a;
  color: white;
  font-weight: bold;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 1.2em;
}
</style>
