<template>
  <v-card class="pa-4">
    <h2>{{ $t('user.createUser.title') }}</h2>
    <v-container>
      <v-form ref="createUserForm" v-model="valid" lazy-validation>
        <v-row>
          <!-- Name input -->
          <v-col cols="12" lg="6" md="6">
            <v-text-field
              v-model="name"
              :rules="[notEmpty]"
              :label="$t('user.createUser.labels.name')"
              :disabled="loading"
              required
              prepend-icon="mdi-account"
            />
          </v-col>

          <!-- Email input -->
          <v-col cols="12" lg="6" md="6">
            <v-text-field
              v-model="emailInput"
              :rules="[notEmpty, email]"
              :label="$t('user.createUser.labels.email')"
              :disabled="loading"
              :autocomplete="false"
              required
              prepend-icon="mdi-mail"
            />
          </v-col>

          <v-col cols="12" lg="6" md="6">
            <!-- Types select -->
            <v-select
              v-model="type"
              :items="types"
              item-text="type"
              item-value="type"
              :rules="[notEmpty]"
              :label="$t('user.createUser.labels.types')"
              :disabled="loading || !isAddaliaUser"
              prepend-icon="mdi-account-details"
              required
              return-object
            >
              <template v-slot:item="{ item }">
                <span :id="'user-type-option-' + item.type" small class="ma-1">
                  {{ $t('user.createUser.types.' + item.type) }}
                </span>
              </template>
              <template v-slot:selection="{ item }">
                <span
                  :id="'user-type-selected-' + item.type"
                  small
                  class="ma-1"
                  :disabled="loading"
                >
                  {{ $t('user.createUser.types.' + item.type) }}
                </span>
              </template>
            </v-select>
          </v-col>

          <!-- Roles select -->
          <v-col
            cols="12"
            :lg="
              isAddaliaUser &&
              (type.type == 'agent' || type.type == 'distributor' ? 4 : 6)
            "
            md="6"
          >
            <v-select
              v-model="rol"
              :items="type.roles"
              :rules="[notEmpty]"
              :label="$t('user.createUser.labels.roles')"
              :disabled="loading || typeNotSelected"
              prepend-icon="mdi-account-cog"
              required
            >
              <template v-slot:item="{ item }">
                <span :id="'user-rol-option-' + item">
                  {{ $t('user.createUser.roles.' + item) }}
                </span>
              </template>
              <template v-slot:selection="{ item }">
                <span
                  :id="'user-rol-selected-' + item"
                  small
                  class="ma-1"
                  :disabled="loading"
                >
                  {{ $t('user.createUser.roles.' + item) }}
                </span>
              </template>
            </v-select>
          </v-col>
          <v-col
            v-if="
              isAddaliaUser &&
              (type.type == 'agent' || type.type == 'distributor')
            "
            cols="12"
            lg="2"
            md="6"
          >
            <v-text-field
              v-model="percent"
              :label="
                type.type == 'agent'
                  ? $t('user.createUser.commission')
                  : $t('user.createUser.disscount')
              "
              type="number"
              max="100"
              min="0"
              oninput="if (Number(this.value) > Number(this.max)) {this.value = this.max} else {if (Number(this.value) < Number(this.min)) {this.value = this.min}}"
              suffix="%"
              :disabled="loading"
            >
            </v-text-field>
          </v-col>

          <v-row
            class="pl-4 pr-4"
            justify="space-around"
            v-if="isAddaliaUser && type.type == 'collective'"
          >
            <v-col class="mb-0 pb-0" cols="12">
              <span class="subtitle-2">{{
                $t('user.createUser.collectiveParams')
              }}</span>
            </v-col>
            <v-col cols="12" lg="4" md="6">
              <v-text-field
                :rules="[notEmptyNumber]"
                dense
                v-model="collectiveData.newClientRegistrationPrice"
                :label="$t('user.createUser.newClientRegistrationPrice')"
                type="number"
                min="0"
                suffix="€"
                :disabled="loading"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" lg="4" md="6">
              <v-text-field
                dense
                :rules="[notEmptyNumber]"
                v-model="collectiveData.newClientNotifNumber"
                :label="$t('user.createUser.newClientNotifNumber')"
                type="number"
                min="0"
                :disabled="loading"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" lg="4" md="6">
              <v-text-field
                dense
                :rules="[notEmptyNumber]"
                v-model="collectiveData.newClientCifsNumber"
                :label="$t('user.createUser.newClientCifsNumber')"
                type="number"
                min="0"
                :disabled="loading"
              >
              </v-text-field>
            </v-col>
            <v-col class="mb-0 pb-0" cols="12">
              <span class="subtitle-2">
                {{ $t('user.createUser.newClientParams') }}
              </span>
            </v-col>

            <v-col cols="12" lg="4" md="6">
              <v-text-field
                dense
                :rules="[notEmptyNumber]"
                v-model="collectiveData.maxCifsPerClient"
                :label="$t('user.createUser.maxCifsPerClient')"
                type="number"
                min="0"
                :disabled="loading"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" lg="4" md="6">
              <v-text-field
                dense
                :rules="[notEmptyNumber]"
                v-model="collectiveData.addCifNotifNumber"
                :label="$t('user.createUser.addCifNotifNumber')"
                type="number"
                min="0"
                :disabled="loading"
              >
              </v-text-field>
            </v-col>
            <v-col cols="5" lg="1" md="6">
              <v-checkbox
                dense
                value="false"
                disabled
                :label="$t('user.createUser.prepaid')"
              ></v-checkbox>
            </v-col>
            <v-col cols="7" lg="auto" md="6">
              <v-checkbox
                dense
                v-model="collectiveData.autoRenewal"
                :label="$t('user.createUser.autoRenewal')"
                :disabled="loading"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-row>
      </v-form>
    </v-container>

    <v-divider></v-divider>

    <v-card-actions class="headline justify-center mt-2">
      <v-btn
        :disabled="loading"
        :loading="loading"
        color="primary"
        class="mr-4"
        @click="performAction"
      >
        {{ $t('create') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { createUser } from '@/services/users-service'
import rules from '@/utils/rules'
import getErrorText from '@/utils/get-error-text'
export default {
  props: {
    types: Array,
  },
  components: {},
  data() {
    return {
      valid: false,
      loading: false,
      name: '',
      emailInput: '',
      rol: '',
      type: '',
      percent: 0,
      collectiveData: {
        newClientRegistrationPrice: 0,
        newClientNotifNumber: 0,
        newClientCifsNumber: 0,
        maxCifsPerClient: 0,
        addCifNotifNumber: 0,
        autoRenewal: false,
        prepaid: false,
      },
    }
  },
  mounted() {
    if (!this.isAddaliaUser) this.type = this.types[0]
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    ...rules,
    async performAction() {
      if (!this.$refs.createUserForm?.validate()) return

      this.loading = true

      try {
        await createUser(
          this.name,
          this.emailInput,
          this.rol,
          this.type.type,
          this.percent,
          this.collectiveData
        )
        this.setSnackbar({
          position: 'top',
          type: 'success',
          message: this.$t('user.createUser.success.create'),
        })

        this.$router.push({ name: 'UsersView' })
      } catch (error) {
        const message = getErrorText(error.message)
        this.setSnackbar({
          position: 'top',
          type: 'error',
          message,
        })
      }

      this.loading = false
    },
  },
  computed: {
    ...mapGetters(['isAddaliaUser']),
    usersCount() {
      return 0
    },
    filteredUsers() {
      return []
    },
    typeNotSelected() {
      return !this.type
    },
  },
}
</script>
