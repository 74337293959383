<template>
  <v-container>
    <v-row justify="center" align="center">
      <v-col
        cols="12"
        :md="isConfiguration ? 12 : 6"
        :sm="isConfiguration ? 12 : 6"
        no-gutters
      >
        <v-card>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="body-1 text-center">
                    {{ $t('units') }}
                    <v-btn
                      @click="packagesAmount = minimumAmount"
                      v-if="!!packagesAmount && !isConfiguration"
                      icon
                      dense
                    >
                      <v-icon small>mdi-delete</v-icon>
                    </v-btn>
                  </th>
                  <th class="body-1 text-center">{{ $t('price') }}</th>
                  <th v-if="isConfiguration" class="body-1 text-center">
                    {{ $t('delete') }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in filteredPricesModel" :key="item.amount">
                  <td
                    @click="
                      packagesAmount =
                        Number(packagesAmount) + Number(item.amount)
                    "
                  >
                    {{ $n(item.amount, 'i18nCount') }}
                    <span
                      v-if="
                        !isConfiguration &&
                        countOccurrences(totalPackagesComputed, item.amount)
                      "
                    >
                      (x{{
                        countOccurrences(totalPackagesComputed, item.amount)
                      }})
                    </span>
                  </td>
                  <td
                    @click="
                      packagesAmount =
                        Number(packagesAmount) + Number(item.amount)
                    "
                  >
                    {{ $n(item.totalPrice, 'i18nAmount') }}€
                  </td>
                  <td v-if="isConfiguration">
                    <v-icon @click.stop="deleteRow(item)">mdi-delete</v-icon>
                  </td>
                </tr>
                <tr v-if="activateAddRow" style="height: 50px">
                  <td>
                    <v-text-field
                      v-model="units"
                      :rules="[notEmptyNumber]"
                      :placeholder="$t('units')"
                    >
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      v-model="price"
                      :rules="[notEmptyNumber]"
                      :placeholder="$t('price')"
                    >
                      €
                    </v-text-field>
                  </td>
                  <span v-if="isConfiguration">
                    <v-icon @click.stop="activateAddRow = false"
                      >mdi-delete</v-icon
                    >
                    <v-icon
                      v-if="checkButtonRulesComputed"
                      @click.stop="addConfirmation()"
                      >mdi-check</v-icon
                    >
                  </span>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
      <v-col v-if="isConfiguration" class="ma-0 pa-0" cols="12" no-gutters>
        <v-btn
          @click.stop="addRow()"
          :disabled="confirmLoading"
          :loading="confirmLoading"
          class="ma-0 pa-0"
          plain
          dense
          ><v-icon x-large>mdi-plus</v-icon>
        </v-btn>
      </v-col>

      <v-col v-if="!isConfiguration" cols="12" md="6" sm="6">
        <v-row>
          <v-col :cols="totalPackagesComputed.length > 0 ? 4 : 12">
            <v-text-field
              disabled
              v-model="packagesAmount"
              dense
              :rules="[notEmptyNumber]"
              :label="label"
              type="number"
              :id="
                label === $t('numberOfNotifications')
                  ? 'number-of-notifications'
                  : label === $t('numberOfSignatures')
                  ? 'number-of-signatures'
                  : 'number-of-certificates'
              "
            >
            </v-text-field>
          </v-col>
          <v-col v-if="totalPackagesComputed.length > 0" cols="4">
            <v-text-field
              :disabled="isChannelUser || isTrialLicense"
              @focus="selectedDiscountType = 'percentage'"
              v-model="percentDiscount"
              dense
              :label="'% ' + $t('client.cuotaCard.discount')"
              type="number"
              min="0"
              max="100"
              oninput="if (Number(this.value) > Number(this.max)) {this.value = this.max} else {if (Number(this.value) < Number(this.min)) {this.value = this.min}}"
              suffix="%"
            >
            </v-text-field>
          </v-col>
          <v-col v-if="totalPackagesComputed.length > 0" cols="4">
            <v-text-field
              :disabled="isChannelUser || isTrialLicense"
              @focus="selectedDiscountType = 'amount'"
              v-model="moneyDiscount"
              dense
              type="number"
              suffix="€"
              :label="$t('client.cuotaCard.discount')"
            >
            </v-text-field>
          </v-col>
          <v-col v-if="totalPackagesComputed.length > 0" cols="12">
            <v-slider
              :disabled="isChannelUser || isTrialLicense"
              @change="selectedDiscountType = 'bar'"
              :max="totalPackagesPriceComputed"
              min="0"
              v-model="moneyDiscount"
              :label="$t('client.cuotaCard.discount')"
              :thumb-color="$vuetify.theme.dark ? 'white' : 'black'"
              :thumb-size="50"
            >
              <template v-slot:thumb-label="{ value }">
                {{
                  $n((value * 100) / totalPackagesPriceComputed, 'i18nAmount')
                }}%</template
              >
            </v-slider>
          </v-col>
        </v-row>

        <h3 v-if="totalPackagesPriceComputed > 0" class="mt-4 mb-4">
          <span class="notification-label">
            {{ $t('client.packagesLicenses.finalPrice') }}
          </span>
          <!--
          <br />
         
          <span class="notification-info">
            <span class="notification-value">
              Paquetes seleccionados: {{ totalPackagesComputed }}
            </span>
          </span>
          <br />
          -->
          <span v-if="percentDiscount">
            <span class="notification-currency">
              {{ $n(totalPackagesPriceComputed, 'i18nAmount') }}
              €
            </span>
            -
            <span class="notification-discount">
              {{ $n(moneyDiscount, 'i18nAmount') }}€ ({{
                $n(percentDiscount, 'i18nAmount')
              }}%)
            </span>
            =
          </span>
          <span class="notification-total">
            {{ $n(totalPackagesPriceComputed - moneyDiscount, 'i18nAmount') }}
            €
          </span>
        </h3>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import rules from '@/utils/rules'

import { updateBilling } from '@/services/billing-service'

export default {
  props: {
    label: String,
    prices: {
      type: Array,
    },
    packagesList: {
      type: Array,
    },
    name: {
      type: String,
    },
    resultEventName: {
      type: String,
    },
    initialAmount: {
      type: Number,
      default: 0,
    },
    minimumAmount: {
      type: Number,
      default: 0,
    },
    fixedDiscount: {
      type: Number,
      default: 0,
    },
    isConfiguration: {
      type: Boolean,
      default: false,
    },
    resourceName: {
      type: String,
    },
    isTrialLicense: {
      type: Boolean,
      default: false,
    },
  },

  async created() {
    this.packagesAmount = this.initialAmount
    this.pricesModel = this.prices
    this.packagesListChild = this.packagesList
  },
  data() {
    return {
      packagesAmount: 0,
      percentDiscount: 0,
      moneyDiscount: 0,
      selectedDiscountType: 'bar',
      confirmLoading: false,
      activateAddRow: false,
      addPackage: false,
      units: undefined,
      price: undefined,
      validForm: false,
      pricesModel: [],
      packagesListChild: this.packagesList,
    }
  },
  computed: {
    ...mapGetters(['isChannelUser', 'isWholesalerUser']),
    filteredPricesModel() {
      return this.pricesModel
        .filter(
          (item) =>
            !item.type ||
            (item.type === 'wholesalerPrice' && this.isWholesalerUser)
        )
        .sort((a, b) => a.amount - b.amount)
    },
    totalPackagesComputed() {
      //listado de pauetes de firmas a contratar
      return this.restarNumerosHastaMenor(
        this.packagesListChild,
        this.packagesAmount
      )
    },
    checkButtonRulesComputed() {
      return (
        this.units !== '' &&
        this.units !== undefined &&
        this.units !== null &&
        this.units > 0 &&
        this.units !== null &&
        this.units > 0 &&
        !this.pricesModel.some((item) => item.amount === this.units) &&
        this.price !== '' &&
        this.price !== undefined &&
        this.price !== null &&
        this.price > 0 &&
        !this.pricesModel.some((item) => item.totalPrice === this.price)
      )
    },
    totalPackagesPriceComputed() {
      //Calcula el precio de los paquetes contratados
      let precioTotal = 0
      for (let i = 0; i < this.totalPackagesComputed.length; i++) {
        const paqueteSeleccionado = this.totalPackagesComputed[i]
        const precioPaquete = this.prices.find(
          (price) => price.amount === paqueteSeleccionado
        )?.totalPrice
        if (precioPaquete) {
          precioTotal += precioPaquete
        }
      }

      return precioTotal
    },
    finalPriceComputed() {
      return {
        concept: this.resultEventName,
        packages: this.totalPackagesComputed,
        price: this.totalPackagesPriceComputed,
        discountAmount: this.moneyDiscount,
        discountPercentage: this.percentDiscount,
      }
      /*
      return (
        this.totalPackagesPriceComputed -
        (this.totalPackagesPriceComputed * this.percentDiscount) / 100
      )*/
    },
  },
  watch: {
    moneyDiscount: function (newAmount) {
      if (
        this.selectedDiscountType === 'amount' ||
        this.selectedDiscountType === 'bar'
      ) {
        // Verificar si el nuevo valor es un número válido
        if (!isNaN(newAmount) && newAmount !== null) {
          // Calcular el descuento como el porcentaje del monto sobre el valor total
          this.percentDiscount = (
            (newAmount * 100) /
            this.totalPackagesPriceComputed
          ).toFixed(2)
        } else {
          // Si el valor introducido no es un número válido, establecer el descuento como 0
          this.percentDiscount = 0
        }
      }
    },
    percentDiscount: function (newAmount) {
      if (this.selectedDiscountType === 'percentage') {
        if (newAmount < 0) {
          this.percentDiscount = 0
        } else if (newAmount > 100) {
          this.percentDiscount = 100
        }

        // Verificar si el nuevo valor es un número válido
        if (!isNaN(newAmount) && newAmount !== null) {
          // Calcular el descuento como el porcentaje del monto sobre el valor total
          this.moneyDiscount = (
            (newAmount / 100) *
            this.totalPackagesPriceComputed
          ).toFixed(2)
        } else {
          // Si el valor introducido no es un número válido, establecer el descuento como 0
          this.moneyDiscount = 0
        }
      }
    },
    finalPriceComputed(newValue) {
      // Emitir el evento con el nuevo valor de la computed property
      if (!this.isConfiguration) {
        //no emitir nada si estamos en modo configuracion
        this.$emit(this.resultEventName, newValue)
      }
    },
    packagesAmount() {
      this.percentDiscount = this.fixedDiscount > 0 ? this.fixedDiscount : 0
      this.moneyDiscount =
        (this.totalPackagesPriceComputed * this.percentDiscount) / 100
    },
    // Sincroniza slider value si fixedDiscount cambia en el padre al activar/desactivar el checkbox de licencia trial
    fixedDiscount(newVal) {
      this.percentDiscount = newVal
      this.moneyDiscount =
        (this.totalPackagesPriceComputed * this.percentDiscount) / 100
    },
  },
  methods: {
    ...rules,
    ...mapMutations(['setSnackbar']),
    triggerNotRenewalLogic() {
      this.$emit('doNotRenewSignatures')
    },
    async addRow() {
      this.activateAddRow = true
    },
    async addConfirmation() {
      try {
        this.pricesModel.push({ amount: this.units, totalPrice: this.price })
        let dataUpdate = {}
        dataUpdate = {
          [this.resourceName]: this.filteredPricesModel,
        }

        await updateBilling(dataUpdate)

        // Limpiamos los valores despues de añadir la fila
        this.units = undefined
        this.price = undefined
        const message = this.$t('updatePackageSuccess')
        this.setSnackbar({ position: 'top', type: 'success', message })
      } catch (error) {
        const message = this.$t('updatePackageError')
        this.setSnackbar({ position: 'top', type: 'error', message })
      } finally {
        this.confirmLoading = false
      }
    },
    obtenerNumeroMasGrande(listaNumeros, numeroDado) {
      let numeroMasGrande = null

      for (let i = 0; i < listaNumeros?.length; i++) {
        const numeroActual = listaNumeros[i]

        if (
          numeroActual <= numeroDado &&
          (numeroMasGrande === null || numeroActual > numeroMasGrande)
        ) {
          numeroMasGrande = numeroActual
        }
      }

      return numeroMasGrande
    },
    restarNumerosHastaMenor(listaNumeros, numeroDado) {
      let resultado = []
      let numeroActual = numeroDado

      if (listaNumeros)
        while (numeroActual >= Math.min(...listaNumeros)) {
          const numeroMasGrande = this.obtenerNumeroMasGrande(
            listaNumeros,
            numeroActual
          )

          if (numeroMasGrande !== null) {
            resultado.push(numeroMasGrande)
            numeroActual -= numeroMasGrande
          } else {
            break
          }
        }

      return resultado
    },
    countOccurrences(array, element) {
      return array.reduce(function (count, currentElement) {
        if (currentElement === element) {
          count++
        }
        return count
      }, 0)
    },
    async deleteRow(item) {
      try {
        // await deleteRowFromBilling(item)
        const index = this.pricesModel.findIndex(
          (element) => element.amount === item.amount
        )
        this.pricesModel.splice(index, 1)
        let dataUpdate = {}
        dataUpdate = {
          [this.resourceName]: this.filteredPricesModel,
        }
        await updateBilling(dataUpdate)

        const message = this.$t('deletePackageSuccess')
        this.setSnackbar({ position: 'top', type: 'success', message })
      } catch (error) {
        const message = this.$t('deletePackageError')
        this.setSnackbar({ position: 'top', type: 'error', message })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.notification-label {
  font-size: 1.2rem;
  font-weight: bold;
}

.notification-info {
  font-size: 1.2rem;
}

.notification-value {
  font-weight: bold;
}

.notification-currency {
  font-size: 1.1rem;
}

.notification-discount {
  font-size: 1.1rem;
  font-weight: bold;
}

.notification-total {
  font-size: 1.3rem;
  font-weight: bold;
}
.final-price-label {
  font-size: 1.8rem;
  font-weight: bold;
}

.final-price-value {
  font-size: 2.4rem;
  font-weight: bold;
}
</style>
