<template>
  <!--v-container fill-height fluid class="text-center"-->
  <v-container>
    <v-form
      ref="form"
      lazy-validation
      @submit.prevent="changeManagementMailingList"
    >
      <!--v-row justify="center" align="center" class="mb-n1">
        <v-col cols="12">
          <h1 class="mt-n5 mb-n5 text-center">
            {{ $t('managementMailingListConfiguration') }}
          </h1>
        </v-col>
      </v-row-->

      <v-row class="mb-2" justify="center" align="center">
        <v-col cols="12" md="8">
          <v-row>
            <v-col cols="12" md="6">
              <!-- lista de distribucion de correo -->
              <v-card elevation="1" class="ma-2 pa-2">
                <v-card-title>{{
                  $t('managementMailingListConfiguration')
                }}</v-card-title>
                <v-row class="ml-2" align="center">
                  <v-col cols="12" md="12">
                    <v-text-field
                      v-model="managementMailingList"
                      :label="$t('managementMailingList')"
                      type="email"
                      prepend-icon="mdi-email-open-multiple"
                      :rules="emailRules"
                      :disabled="confirmLoading"
                      id="managementEmail-input"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row justify="start" class="pa-0">
                  <v-col cols="auto" class="pt-0 pb-2">
                    <v-btn
                      v-if="
                        isManagementMailingListChanged &&
                        this.$refs.form?.validate()
                      "
                      type="button"
                      @click.stop="changeManagementMailingList()"
                      :disabled="confirmLoading"
                      :loading="confirmLoading"
                      class="ml-10 pa-0"
                      text
                      color="primary"
                    >
                      {{ $t('client.customBrand.save') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>

            <!-- consumo licencias cif -->
            <v-col cols="12" md="6">
              <v-card elevation="1" class="ma-2 pa-2">
                <v-card-title>{{
                  $t('consumoLicenciasCifGDConfiguration')
                }}</v-card-title>
                <v-row class="ml-2" align="center">
                  <v-col cols="12" md="12">
                    <v-text-field
                      v-model="consumoLicenciasCifGD"
                      :label="$t('consumoLicenciasCifGD')"
                      type="number"
                      prepend-icon="mdi-numeric"
                      :rules="licensesCifRules"
                      :disabled="confirmLoading"
                      id="consumoLicenciasCifGD-input"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row justify="start" class="pa-0">
                  <v-col cols="auto" class="pt-0 pb-2">
                    <v-btn
                      v-if="
                        isConsumoLicenciasCifGDChanged &&
                        this.$refs.form?.validate()
                      "
                      type="button"
                      @click.stop="changeConsumoLicenciasCifGD()"
                      :disabled="confirmLoading"
                      :loading="confirmLoading"
                      class="ml-10 pa-0"
                      text
                      color="primary"
                    >
                      {{ $t('client.customBrand.save') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
    <br />

    <v-row justify="center" align="center" class="mb-n1">
      <v-col cols="12">
        <h1 class="mt-n5 mb-n5 text-center">
          {{ $t('priceConfiguration') }}
        </h1>
      </v-col>
    </v-row>
    <v-row justify="center" align="center" class="mb-n1">
      <v-col cols="6" md="4">
        <v-tabs
          v-model="userTab"
          background-color="transparent"
          color="primary"
          grow
        >
          <v-tab disabled> {{ $t('wholesaler') }} </v-tab>
          <v-tab> {{ $t('general') }} </v-tab>
        </v-tabs>
      </v-col>
      <v-col cols="6" md="4">
        <v-tabs
          v-model="companyTab"
          background-color="transparent"
          color="orange"
          grow
        >
          <v-tab> {{ $t('empresa') }} </v-tab>
          <v-tab> {{ $t('asesoria') }} </v-tab>
        </v-tabs>
      </v-col>
    </v-row>

    <v-row justify="center" align="center" class="mt-n1">
      <v-col cols="8">
        <v-card>
          <v-tabs vertical>
            <v-tab>
              <v-icon left> mdi-account </v-icon>
              {{ $t('client.cuotaCard.cardTitle') }}
            </v-tab>
            <v-tab>
              <v-icon left> mdi-email </v-icon>
              {{ $t('cifsNotifications') }}
            </v-tab>
            <v-tab>
              <v-icon left> mdi-email </v-icon>
              {{ $t('packagesNotifications') }}
            </v-tab>
            <v-tab>
              <v-icon left> mdi-draw </v-icon>
              {{ $t('certificatesSignatures') }}
            </v-tab>
            <v-tab>
              <v-icon left> mdi-draw </v-icon>
              {{ $t('packagesSignatures') }}
            </v-tab>
            <v-tab>
              <v-icon left> mdi-security </v-icon>
              {{ $t('additionalServices') }}
            </v-tab>

            <v-tab-item class="pa-5">
              <CuotaCard
                v-show="companyTab == 0"
                :price="billing.empresaAppCuota"
                :isConfiguration="true"
                :companyType="companyTab == 0 ? 'EMPRESA' : 'ASESORIA'"
                :showTitle="false"
              />
              <CuotaCard
                v-show="companyTab == 1"
                :price="billing.despachoAppCuota"
                :isConfiguration="true"
                :companyType="companyTab == 0 ? 'EMPRESA' : 'ASESORIA'"
                :showTitle="false"
              />
            </v-tab-item>
            <v-tab-item class="pa-5">
              <v-card flat>
                <CuotaLicenses
                  v-show="companyTab == 0"
                  :label="$t('numberOfCifs')"
                  :cuotaServicio="billing.empresaNotificacionesCif"
                  :isConfiguration="true"
                  :companyType="companyTab == 0 ? 'EMPRESA' : 'ASESORIA'"
                />
                <CuotaLicenses
                  v-show="companyTab == 1"
                  :label="$t('numberOfCifs')"
                  :cuotaServicio="billing.despachoNotificacionesCif"
                  :isConfiguration="true"
                  :companyType="companyTab == 0 ? 'EMPRESA' : 'ASESORIA'"
                />
              </v-card>
            </v-tab-item>
            <v-tab-item class="pa-5">
              <div v-show="companyTab == 0">
                <PackagesLicenses
                  :prices="billing.empresaNotificacionesPaquetesCuota"
                  :packageList="billing.empresaNotificacionesPaquetes"
                  :isConfiguration="true"
                  resourceName="empresaNotificacionesPaquetesCuota"
                />
              </div>
              <div v-show="companyTab == 1">
                <PackagesLicenses
                  :prices="billing.despachoNotificacionesPaquetesCuota"
                  :packageList="billing.despachoNotificacionesPaquetes"
                  :isConfiguration="true"
                  resourceName="despachoNotificacionesPaquetesCuota"
                />
              </div>
            </v-tab-item>
            <v-tab-item class="pa-5">
              <div v-show="companyTab == 0">
                <PackagesLicenses
                  :prices="billing.empresaFirmasCertificadosCuota"
                  :packageList="billing.empresaFirmasCertificados"
                  :isConfiguration="true"
                  resourceName="empresaFirmasCertificadosCuota"
                />
              </div>
              <div v-show="companyTab == 1">
                <PackagesLicenses
                  :prices="billing.despachoFirmasCertificadosCuota"
                  :packageList="billing.despachoFirmasCertificados"
                  :isConfiguration="true"
                  resourceName="despachoFirmasCertificadosCuota"
                />
              </div>
            </v-tab-item>
            <v-tab-item class="pa-5">
              <div v-show="companyTab == 0">
                <PackagesLicenses
                  :prices="billing.empresaFirmasPaquetesCuota"
                  :packageList="billing.empresaFirmasPaquetes"
                  :isConfiguration="true"
                  resourceName="empresaFirmasPaquetesCuota"
                />
              </div>
              <div v-show="companyTab == 1">
                <PackagesLicenses
                  :prices="billing.despachoFirmasPaquetesCuota"
                  :packageList="billing.despachoFirmasPaquetes"
                  :isConfiguration="true"
                  resourceName="despachoFirmasPaquetesCuota"
                />
              </div>
            </v-tab-item>
            <v-tab-item class="pa-5">
              <div v-show="companyTab == 0">
                <AdditionalServices
                  :multiFactorPrice="billing.empresa2FACuota"
                  :customBrandPrice="billing.empresaBrandCuota"
                  :isConfiguration="true"
                  companyType="EMPRESA"
                />
              </div>
              <div v-show="companyTab == 1">
                <AdditionalServices
                  :multiFactorPrice="billing.despacho2FACuota"
                  :customBrandPrice="billing.despachoBrandCuota"
                  :isConfiguration="true"
                  companyType="ASESORIA"
                />
              </div>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CuotaCard from '@/components/client/createClient/CuotaCard.vue'
import CuotaLicenses from '@/components/client/createClient/CuotaLicenses.vue'
import PackagesLicenses from '@/components/client/createClient/PackagesLicenses.vue'
import AdditionalServices from '@/components/client/createClient/AdditionalServices.vue'

import rules from '@/utils/rules'
import { mapMutations } from 'vuex'
import { updateBilling } from '@/services/billing-service'

export default {
  props: {
    billing: Object,
  },
  components: {
    CuotaCard,
    CuotaLicenses,
    PackagesLicenses,
    AdditionalServices,
  },
  async created() {
    this.managementMailingList =
      this.$store.state.appBilling.aManagementMailingList
    this.consumoLicenciasCifGD =
      this.$store.state.appBilling.consumoLicenciasCifGD
  },
  data() {
    return {
      emailRules: [rules.email],
      licensesCifRules: [
        rules.notEmptyNumber,
        rules.maxNumberValue(99),
        rules.minNumberValue(10),
      ],
      selectedPlan: 0,
      CIFsCuotaPrice: 0,
      confirmLoading: false,
      packageType: undefined,
      addNotificationsPackage: false,
      addSignaturesPackage: false,
      addCertificatesPackage: false,
      NotificationsPackagePrice: 0,
      signaturesCertsPackagesPrice: 0,
      companyTab: 0,
      userTab: 1,
      managementMailingList:
        this.$store.state.appBilling.aManagementMailingList, // atributo con una "a" por delante (para que se muestre el primero en la consola de firebase)
      consumoLicenciasCifGD: 0,
    }
  },
  computed: {
    isManagementMailingListChanged() {
      return (
        this.managementMailingList !==
        this.$store.state.appBilling.aManagementMailingList
      )
    },
    isConsumoLicenciasCifGDChanged() {
      return (
        this.consumoLicenciasCifGD !==
        this.$store.state.appBilling.consumoLicenciasCifGD
      )
    },
  },
  methods: {
    ...rules,
    ...mapMutations(['setSnackbar']),
    toggleSelection() {
      this.$emit('selectProductNotificationsEvent')
    },
    selectPlan(data) {
      this.selectedPlan = data.id
    },
    notificationsPackagesEvent(newValue) {
      this.NotificationsPackagePrice = Number(
        newValue.price - newValue.discountAmount
      )
      this.$emit('notificationsPackages', newValue)
    },
    notificationsCuotaEvent(newValue) {
      this.CIFsCuotaPrice = Number(newValue.price - newValue.discountAmount)
      newValue.packages = [parseInt(newValue.amount)]
      this.$emit('notificationsCuota', newValue)
    },
    signaturesCertsPackagesEvent(newValue) {
      this.signaturesCertsPackagesPrice = Number(
        newValue.price - newValue.discountAmount
      )
      this.$emit('signaturesCertsPackages', newValue)
    },
    signaturesPackagesEvent(newValue) {
      this.signaturesPackagesPrice = Number(
        newValue.price - newValue.discountAmount
      )
      this.$emit('signaturesPackages', newValue)
    },
    goodOkCuotaEvent(newValue) {
      this.goodOkCuota = newValue
    },
    async changeManagementMailingList() {
      if (
        this.isManagementMailingListChanged == false ||
        !this.$refs.form?.validate()
      ) {
        return
      }
      try {
        this.confirmLoading = true
        let dataUpdate = { aManagementMailingList: this.managementMailingList }

        await updateBilling(dataUpdate)
        const message = this.$t('updateManagementMailingListSuccess')
        this.setSnackbar({ position: 'top', type: 'success', message })
      } catch (error) {
        const message = this.$t('updateManagementMailingListError')
        this.setSnackbar({ position: 'top', type: 'error', message })
      } finally {
        this.confirmLoading = false
      }
    },
    async changeConsumoLicenciasCifGD() {
      if (
        this.isConsumoLicenciasCifGDChanged == false ||
        !this.$refs.form?.validate()
      ) {
        return
      }
      try {
        this.confirmLoading = true
        let dataUpdate = {
          consumoLicenciasCifGD: Number(this.consumoLicenciasCifGD),
        }
        await updateBilling(dataUpdate)

        const message = this.$t('updateConsumoLicenciasCifGDSuccess')
        this.setSnackbar({ position: 'top', type: 'success', message })
      } catch (error) {
        const message = this.$t('updateConsumoLicenciasCifGDError')
        this.setSnackbar({ position: 'top', type: 'error', message })
      } finally {
        this.confirmLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.v-tabs-items.full-height-tab .v-window-item {
  height: calc(100vh - 270px); /* adjust 270px to suits your needs */
  overflow-y: auto;
}
</style>
