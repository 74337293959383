const state = {
  dialog: {},
  notFound: false,
  snackbar: {},
  loading: false,
  clients: [],
  clientsListOptions: {},
  clientsSearch: '',
  client: undefined,
  clientsUnsubscribe: null,
  clientUnsubscribe: null,
  usersListOptions: {},
  usersSearch: '',
  arrayListener: [],
  appBilling: {
    // Paquetes EMPRESA
    empresaAppCuota: 0,
    empresaNotificacionesCif: 0,
    empresaNotificacionesPaquetesCuota: [],
    empresaNotificacionesPaquetes: [], //para simplificar el algoritmo de restarNumerosHastaMenor
    empresaFirmasCertificadosCuota: [],
    empresaFirmasCertificados: [], //para simplificar el algoritmo de restarNumerosHastaMenor
    empresaFirmasPaquetesCuota: [],
    empresaFirmasPaquetes: [], //para simplificar el algoritmo de restarNumerosHastaMenor
    empresa2FACuota: 0,
    empresaBrandCuota: 0,
    //Paquetes DESPACHO
    despachoAppCuota: 0,
    despachoNotificacionesCif: 0,
    despachoNotificacionesPaquetesCuota: [],
    despachoNotificacionesPaquetes: [], //para simplificar el algoritmo de restarNumerosHastaMenor
    despachoFirmasCertificadosCuota: [],
    despachoFirmasCertificados: [], //para simplificar el algoritmo de restarNumerosHastaMenor
    despachoFirmasPaquetesCuota: [],
    despachoFirmasPaquetes: [], //para simplificar el algoritmo de restarNumerosHastaMenor
    despacho2FACuota: 0,
    despachoBrandCuota: 0,
    // Paquetes Tokens MAYORISTA
    mayoristaTokenPaquetesCuota: [],
    mayoristaTokenPaquetes: [], //para simplificar el algoritmo de restarNumerosHastaMenor
    // Lista de correo de Gestión
    aManagementMailingList: '',
    // numero de licencias usadas por cada CIF gran destinatario de un Despacho
    consumoLicenciasCifGD: 0,
  },
  news: [],
  history: [],
  newsUnsubscribe: () => {},
}

export default state
