<template>
  <v-dialog v-model="dialog" id="client-renew-dialog">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        text
        @click="dialog = false"
        color="primary"
        v-bind="attrs"
        v-on="on"
        id="renew-button"
      >
        {{ $t('client.clientDetail.renew') }}
      </v-btn>
    </template>

    <v-card id="renew-card">
      <v-card-title class="text-h5 grey lighten-2" id="renew-card-title">
        {{ $t('renewService') }}
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-container>
        <v-row
          class="mb-1"
          justify="center"
          v-if="
            isAddaliaUser &&
            this.client.trialLicense &&
            allowToPerformAction('changeClientInformation')
          "
        >
          <v-col cols="10">
            <div class="text-start pl-0">
              <v-row class="ml-1 mt-1 ps-0">
                <v-col cols="auto" class="pl-4 pb-0">
                  <h2>{{ $t('trialLicense') }}</h2>

                  <v-checkbox
                    v-model="cliente.trialLicense"
                    class="ma-0 pa-0 large-checkbox custom-label-color"
                    :label="$t('trialLicenseCheckboxLabel')"
                    dense
                    :disabled="loading"
                    id="trialLicense-checkbox"
                  >
                  </v-checkbox>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>

        <v-row class="mb-3" justify="center">
          <v-col cols="10" class="pt-0">
            <CuotaCard
              :showTitle="false"
              :fixedDiscount="calculateDiscount()"
              :price="goodOkCuotaBefore"
              :expirationDate="client.plan?.expirationLicense.toDate()"
              :isTrialLicense="cliente.trialLicense"
              @goodOkCuota="goodOkCuotaEvent"
              id="cuota-card"
            />
          </v-col>
          <v-col
            :cols="client.firmasActivas ? 5 : 10"
            v-if="client.notificacionesActivas"
          >
            <v-card class="pa-5">
              <v-row
                @click="renewNotificationsFlag = !renewNotificationsFlag"
                justify="space-between"
              >
                <v-col cols="auto">
                  <v-icon color="primary" v-if="renewNotificationsFlag">
                    mdi-check-circle
                  </v-icon>
                  <v-icon v-else> mdi-circle-outline </v-icon>

                  <v-icon x-large> mdi-mailbox </v-icon>
                </v-col>
                <v-col cols="auto">
                  <h2>{{ $t('client.clientDetail.notificationsActive') }}</h2>
                </v-col>
              </v-row>
              <v-card-text v-if="!renewNotificationsFlag">
                <v-icon color="red">mdi-alert-circle</v-icon>
                <span class="red--text">{{ $t('doNotRenewServiceText') }}</span>
              </v-card-text>
              <v-expand-transition>
                <CuotaLicenses
                  v-if="renewNotificationsFlag"
                  :elevation="0"
                  :initialAmount="client.plan.notificationsMaxCifs"
                  :minimumAmount="minimumNotificationsCifs"
                  :fixedDiscount="calculateDiscount()"
                  :label="$t('numberOfCifs')"
                  :cuotaServicio="notificacionesCif"
                  :isTrialLicense="cliente.trialLicense"
                  resultEventName="notificationsCuota"
                  @notificationsCuota="upgradeCuotaEvent"
                  id="cuota-licenses"
                />
              </v-expand-transition>
            </v-card>
          </v-col>
          <v-col
            :cols="client.notificacionesActivas ? 5 : 10"
            v-if="client.firmasActivas"
          >
            <v-card class="pa-5">
              <v-row
                @click="renewSignaturesFlag = !renewSignaturesFlag"
                justify="space-between"
              >
                <v-col cols="auto">
                  <v-icon color="primary" v-if="renewSignaturesFlag">
                    mdi-check-circle
                  </v-icon>
                  <v-icon v-else> mdi-circle-outline </v-icon>

                  <v-icon x-large> mdi-draw </v-icon>
                </v-col>
                <v-col cols="auto">
                  <h2>{{ $t('client.clientDetail.signaturesActive') }}</h2>
                </v-col>
              </v-row>
              <v-card-text
                class="noteBoxes typeOrange"
                v-if="!renewSignaturesFlag"
              >
                <v-icon color="red">mdi-alert-circle</v-icon>
                <span class="red--text">{{ $t('doNotRenewServiceText') }}</span>
              </v-card-text>
              <v-expand-transition>
                <PackagesLicenses
                  v-if="renewSignaturesFlag"
                  :initialAmount="client.plan.signaturesMaxCerts"
                  :minimumAmount="minimumSignaturesCifs"
                  :fixedDiscount="calculateDiscount()"
                  :name="$t('numberCertificates')"
                  :label="$t('numberCertificates')"
                  :prices="firmasCertificadosCuota"
                  :packagesList="firmasCertificados"
                  :isTrialLicense="cliente.trialLicense"
                  resultEventName="signaturesCertsPackages"
                  @signaturesCertsPackages="signaturesCertsPackagesEvent"
                  id="packages-licenses"
                />
              </v-expand-transition>
            </v-card>
          </v-col>

          <v-col cols="10">
            <AdditionalServices
              :multiFactorPrice="multiFactor"
              :customBrandPrice="customBrand"
              :fixedDiscount="calculateDiscount()"
              :selected2FA="client.allowed2FA"
              :selectedCustomBrand="client.allowedCustomBrand"
              :isTrialLicense="cliente.trialLicense"
              @customServices="customServicesEvent"
              id="additional-services"
            />
          </v-col>
        </v-row>
        <v-divider></v-divider>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          color="primary"
          @click="confirmRenew"
          :disabled="loading"
          :loading="loading"
          id="confirm-purchase-button"
        >
          {{ $t('confirmPurchase') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CuotaCard from '@/components/client/createClient/CuotaCard.vue'
import CuotaLicenses from '@/components/client/createClient/CuotaLicenses.vue'
import PackagesLicenses from '@/components/client/createClient/PackagesLicenses.vue'
import AdditionalServices from '@/components/client/createClient/AdditionalServices.vue'
import { createInvoice } from '@/services/clients-service'
import { mapGetters, mapMutations } from 'vuex'
import { Firestore } from '@/firebase-exports'
import { updateClient } from '@/services/clients-service'
import { allowToPerformAction } from '@/services/users-service'

export default {
  props: {
    client: {
      type: Object,
      required: true,
    },
    minimumNotificationsCifs: {
      type: Number,
      default: 0,
    },
    minimumSignaturesCifs: {
      type: Number,
      default: 0,
    },
    typeProp: {
      type: String,
    },
  },
  components: {
    CuotaLicenses,
    PackagesLicenses,
    AdditionalServices,
    CuotaCard,
  },
  data() {
    return {
      renewNotificationsFlag: this.client.notificacionesActivas,
      renewSignaturesFlag: this.client.firmasActivas,
      dialog: false,
      loading: false,
      goodOkCuotaBefore:
        this.client.tipoEmpresa == 'EMPRESA'
          ? this.$store.state.appBilling.empresaAppCuota
          : this.$store.state.appBilling.despachoAppCuota,

      notificacionesCif:
        this.client.tipoEmpresa == 'EMPRESA'
          ? this.$store.state.appBilling.empresaNotificacionesCif
          : this.$store.state.appBilling.despachoNotificacionesCif,

      firmasCertificadosCuota:
        this.client.tipoEmpresa == 'EMPRESA'
          ? this.$store.state.appBilling.empresaFirmasCertificadosCuota
          : this.$store.state.appBilling.despachoFirmasCertificadosCuota,
      firmasCertificados:
        this.client.tipoEmpresa == 'EMPRESA'
          ? this.$store.state.appBilling.empresaFirmasCertificados
          : this.$store.state.appBilling.despachoFirmasCertificados, //para simplificar el algoritmo de restarNumerosHastaMenor

      multiFactor:
        this.client.tipoEmpresa == 'EMPRESA'
          ? this.$store.state.appBilling.empresa2FACuota
          : this.$store.state.appBilling.despacho2FACuota,
      customBrand:
        this.client.tipoEmpresa == 'EMPRESA'
          ? this.$store.state.appBilling.empresaBrandCuota
          : this.$store.state.appBilling.despachoBrandCuota,
      services: [],
      goodOkCuota: {},
      notificationsCuota: {},
      customServices: {},
      signaturesCertsPackages: {},
      cliente: {
        trialLicense: this.client.trialLicense,
      },
    }
  },
  computed: {
    ...mapGetters(['isAddaliaUser', 'isDistributorUser']),
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    allowToPerformAction(action) {
      return allowToPerformAction(this.$store.state.user.rol, action)
    },
    goodOkCuotaEvent(event) {
      this.goodOkCuota = event
    },
    upgradeCuotaEvent(event) {
      this.notificationsCuota = event
    },
    signaturesCertsPackagesEvent(event) {
      this.signaturesCertsPackages = event
    },
    customServicesEvent(event) {
      this.customServices = event.filter((service) => service.selected)
    },
    async confirmRenew() {
      this.loading = true
      let messages = []
      try {
        let renewalData = [
          this.goodOkCuota,
          ...(this.renewNotificationsFlag ? [this.notificationsCuota] : []),
          ...(this.renewSignaturesFlag ? [this.signaturesCertsPackages] : []),
        ]
        if (this.customServices && Array.isArray(this.customServices))
          renewalData = [...renewalData, ...this.customServices]
        renewalData
          .filter((obj) => obj !== undefined && Object.keys(obj).length > 0)
          .forEach((obj) => this.services.push(obj))

        for (const item of this.services) {
          const newData = {
            ...item,
            type: this.typeProp,
            status: !this.cliente.trialLicense
              ? 'pendingBilling'
              : 'pendingActivation',
            date: new Date(),
            selected: false,
          }

          // Creamos los diferentes invoices
          await createInvoice(this.client.id, newData)
        }

        // Si inicialmente se trataba de una licencia de prueba y ahora ya no lo es porque han desactivado el check:
        if (this.client.trialLicense && !this.cliente.trialLicense) {
          try {
            await updateClient(this.client.id, {
              trialLicense:
                this.cliente.trialLicense || Firestore.deleteField(),
            })
            messages.push(this.$t('trialLicenseRemoved'))
            messages.push('') // Salto de línea
          } catch (error) {
            messages.push(this.$t('trialLicenseRemoveError'))
            messages.push('') // Salto de línea
          }
        }

        // Mostramos snackbar con el mensaje de exito
        messages.push(this.$t('packagesAddedSuccess'))
        const message = messages.join('\n')
        this.setSnackbar({ position: 'top', type: 'success', message })
      } catch (error) {
        // Mostramos snackbar con el mensaje de error
        this.loading = false
        messages.push(this.$t('invoiceCreateError'))
        const message = messages.join('\n')
        this.setSnackbar({ position: 'top', type: 'error', message })
      } finally {
        this.dialog = false
        this.dialogConfirmNotRenewal = false
        this.loading = false
      }
    },
    calculateDiscount() {
      this.calculatedDiscount =
        this.cliente.trialLicense == true
          ? 100
          : this.isDistributorUser
          ? this.$store.state.user.percent
          : 0
      return this.calculatedDiscount
    },
    initializeData() {
      this.services = []
      this.cliente = { trialLicense: this.client.trialLicense }
    },
  },
  watch: {
    dialog(newValue) {
      if (newValue) {
        this.initializeData() // Restablecer el estado cada vez que se abre el modal
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
