var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-card-title',[_c('v-row',{staticClass:"d-flex justify-center",attrs:{"no-gutters":""}},[_c('h2',[_vm._v(_vm._s(_vm.$t(_vm.title)))])])],1),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('table',{staticClass:"invoice"},[_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t('invoices.concept')))]),(_vm.includeDates)?_c('th',[_vm._v(" "+_vm._s(_vm.$t('invoices.date'))+" ")]):_vm._e(),_c('th',[_vm._v(_vm._s(_vm.$t('invoices.price')))]),_c('th',[_vm._v(_vm._s(_vm.$t('invoices.amount')))]),_c('th',[_vm._v(_vm._s(_vm.$t('invoices.discount')))]),_c('th',[_vm._v(_vm._s(_vm.$t('invoices.discountAmount')))]),_c('th',[_vm._v(_vm._s(_vm.$t('invoices.finalPrice')))])])]),_c('tbody',_vm._l((_vm.concepts),function(item,index){return _c('tr',{key:index},[(item.price > 0)?[_c('td',[_vm._v(" "+_vm._s(_vm.$t('invoices.' + item.concept))+" ")]),(_vm.includeDates)?_c('td',[(item.date)?_c('span',[_vm._v(_vm._s(_vm.$d(item.date)))]):_c('span',[_vm._v("-")])]):_vm._e(),_c('td',[_vm._v(_vm._s(_vm.$n(item.price, 'i18nAmount'))+" €")]),_c('td',[_vm._v(_vm._s(_vm.formattedPackages(item)))]),_c('td',{staticClass:"align-center"},[(_vm.client.estado !== 'pendienteComercial')?_c('span',[_vm._v(" "+_vm._s(((100 / item.price) * item.discountAmount).toFixed(2))+" % ")]):_c('v-text-field',{style:({ width: '60px' }),attrs:{"disabled":!_vm.isAddaliaCommercialUser || _vm.isTrialLicense,"single-line":"","dense":"","label":'% ' + _vm.$t('client.cuotaCard.discount'),"type":"number","max":"100","min":"0","oninput":"if (Number(this.value) > Number(this.max)) {this.value = this.max} else {if (Number(this.value) < Number(this.min)) {this.value = this.min}}","suffix":"%"},on:{"input":function($event){item.discountAmount =
                        (item.price * item.discountPercentage) / 100}},model:{value:(item.discountPercentage),callback:function ($$v) {_vm.$set(item, "discountPercentage", $$v)},expression:"item.discountPercentage"}})],1),_c('td',[_vm._v(_vm._s(_vm.$n(item.discountAmount, 'i18nAmount'))+" €")]),_c('td',[_vm._v(" "+_vm._s(_vm.$n(item.price - item.discountAmount, 'i18nAmount'))+" € ")])]:_vm._e()],2)}),0)]),_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"8"}},[(_vm.showSum)?_c('h1',{staticClass:"mt-3"},[_vm._v(" "+_vm._s(_vm.$t('finalPrice'))+" "+_vm._s(_vm.$n( _vm.concepts.reduce( (acumulador, objeto) => acumulador + Number(objeto.price) - Number(objeto.discountAmount), 0 ), 'i18nAmount' ))+" € ")]):_vm._e()]),(
              _vm.client.estado !== 'creating' &&
              _vm.isAddaliaUser &&
              _vm.allowToPerformAction('changeInvoiceStatus') &&
              _vm.conceptsChanged
            )?_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{staticClass:"mr-5",attrs:{"dense":"","color":"primary","plain":"","disabled":_vm.confirmLoading,"loading":_vm.confirmLoading},on:{"click":_vm.saveChanges},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_c('v-progress-circular',{attrs:{"indeterminate":"","size":"20","width":"2"}})]},proxy:true}],null,false,337613763)},[_vm._v(" "+_vm._s(_vm.$t('client.clientDetail.save'))+" ")])],1):_vm._e()],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }