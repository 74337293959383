import {
  Firestore,
  UsersManagementCollection,
  UsersManagementCreateUser,
  UsersManagementDoc,
  UsersManagementDeleteUser,
  UpdateUserManagementEmail,
  UsersUpdatePassword,
} from '@/firebase-exports'
import { getDocs, onSnapshot, updateDoc } from 'firebase/firestore'
import store from '@/store/index'

/**
 * Get the user types and roles for the Users component
 * @returns The list of available options
 */
export function getTypes() {
  if (store.getters.isAddaliaUser) {
    return [
      ...(store.getters.isAdminUser
        ? [
            {
              type: 'addalia',
              roles: ['admin', 'commercial', 'billing', 'marketing', 'support'],
            },
          ]
        : []),
      { type: 'agent', roles: ['admin', 'commercial'] },
      { type: 'distributor', roles: ['admin', 'commercial'] },
      { type: 'wholesaler', roles: ['admin', 'commercial'] },
      { type: 'collective', roles: ['admin', 'commercial'] },
    ]
  } else {
    return [{ type: store.state.user.type, roles: ['commercial'] }]
  }
}

/**
 * Crea un nuevo usuario a través de una función en la nube.
 *
 * @param {string} name - Nombre del usuario.
 * @param {string} email - Correo electrónico del usuario.
 * @param {string} rol - Rol del usuario.
 * @param {string} type - Tipo de usuario.
 * @param {number} [percent] - Porcentaje asociado con el usuario, si aplica.
 * @returns {Promise<string>} Una promesa que se resuelve con el ID del nuevo usuario.
 */
export async function createUser(
  name,
  email,
  rol,
  type,
  percent,
  collectiveData
) {
  let data
  switch (type) {
    case 'agent':
    case 'distributor':
      data = await UsersManagementCreateUser({
        name,
        email,
        rol,
        type,
        percent,
      })
      break
    case 'wholesaler':
      data = await UsersManagementCreateUser({ name, email, rol, type })
      break
    case 'collective':
      data = await UsersManagementCreateUser({
        name,
        email,
        rol,
        type,
        collectiveData,
      })
      break
    default:
      data = await UsersManagementCreateUser({ name, email, rol, type })
      break
  }
  return data
}

/**
 * Obtiene los usuarios bajo un administrador específico.
 *
 * @param {string} parentId - El ID del usuario administrador.
 * @returns {Promise<Array<Object>>} Una promesa que se resuelve con un array de usuarios.
 */
export async function getUsers(parentId) {
  const snapshot = await getDocs(
    store.getters.isAddaliaAdminUser
      ? Firestore.query(UsersManagementCollection)
      : store.getters.isAddaliaUser
      ? Firestore.query(
          UsersManagementCollection,
          Firestore.where('addaliaRef', '==', UsersManagementDoc(parentId))
        )
      : Firestore.query(
          UsersManagementCollection,
          Firestore.where('parentRef', '==', UsersManagementDoc(parentId))
        )
  )

  if (snapshot.empty) return []

  return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
}

/**
 * Crea una subscripción a los usuarios bajo un administrador específico.
 *
 * @param {string} parentId - El ID del usuario administrador.
 * @param {Object} context - Contexto del componente donde se realiza la subscripción.
 * @returns {Function} Función para cancelar la subscripción.
 */
export async function getUsersSubscription(parentId, context) {
  const query = store.getters.isAddaliaAdminUser
    ? Firestore.query(UsersManagementCollection)
    : store.getters.isAddaliaUser
    ? Firestore.query(
        UsersManagementCollection,
        Firestore.where('addaliaRef', '==', UsersManagementDoc(parentId))
      )
    : Firestore.query(
        UsersManagementCollection,
        Firestore.where('parentRef', '==', UsersManagementDoc(parentId))
      )

  const unsubscribe = onSnapshot(
    query,
    (snapShot) => {
      if (snapShot.empty) context.users = []

      const users = snapShot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))

      context.users = users

      setTimeout(() => {
        context.loading = false
      }, 1000)
    },
    (error) => {
      throw error
    }
  )
  return unsubscribe
}

/**
 * Obtiene atributos específicos de un usuario padre.
 *
 * @param {string} id - El ID del usuario.
 * @returns {Promise<Object>} Una promesa que se resuelve con los atributos del usuario padre.
 */
export async function getParentAttributes(id) {
  // Used to get some parent attributes to operators
  const data = (await Firestore.getDoc(UsersManagementDoc(id))).data()

  return {
    addaliaRef: data.addaliaRef,
    percent: data.percent,
    collectiveData: data.collectiveData,
  }
}

/**
 * Actualiza la información de un usuario.
 *
 * @param {string} userId - El ID del usuario a actualizar.
 * @param {Object} data - Datos a actualizar del usuario.
 * @returns {Promise<void>} Una promesa que se resuelve cuando se completa la actualización.
 */
export async function updateUser(userId, data) {
  if (
    store.getters.isAddaliaUser &&
    data.type !== 'agent' &&
    data.type !== 'distributor'
  )
    data.percent = Firestore.deleteField()

  if (store.getters.isAddaliaUser && data.type !== 'collective')
    data.collectiveData = Firestore.deleteField()

  // Eliminar todas las claves con valores undefined (p.e. redmineProject y redmineKey) o bien
  // numericas que no sean un numero (p.e. percent)
  Object.keys(data).forEach((key) => {
    if (
      data[key] === undefined ||
      (typeof data[key] === 'number' && isNaN(data[key]))
    ) {
      delete data[key]
    }
  })

  await updateDoc(UsersManagementDoc(userId), data)
}

/**
 * Cambia el correo electrónico de un usuario.
 *
 * @param {string} newEmail - El nuevo correo electrónico del usuario.
 * @param {string} userId - El ID del usuario.
 * @returns {Promise<any>} Una promesa que se resuelve con el resultado de la modificación del correo electrónico.
 */
export async function updateEmail(newEmail, userId) {
  return await UpdateUserManagementEmail({ newEmail, userId })
}

/**
 * Cambia la contraseña de un usuario.
 *
 * @param {string} newPassword - La nueva contraseña del usuario.
 * @returns {Promise<any>} Una promesa que se resuelve con el resultado de la modificación de la contraseña.
 */
export async function updateUserPassword(newPassword) {
  return (await UsersUpdatePassword(newPassword)).data
}

/**
 * Elimina un usuario dado su ID.
 *
 * @param {string} userId - El ID del usuario a eliminar.
 * @returns {Promise<void>} Una promesa que se resuelve cuando se completa la eliminación.
 */
export async function deleteUser(userId) {
  await UsersManagementDeleteUser({ userId })
}

/**
 * Determina si un rol tiene permiso para realizar una acción específica.
 *
 * @param {string} rol - El rol del usuario.
 * @param {string} action - La acción a verificar.
 * @returns {boolean} Verdadero si el rol tiene permiso para realizar la acción.
 */
export function allowToPerformAction(rol, action) {
  switch (rol) {
    case 'admin':
      return [
        'createClient',
        'changeClientInformation',
        'changeUserStatus',
        'assignChannel',
        'assignCommercial',
        'createInvoice',
        'changeInvoiceStatus',
        'activateInvoice',
        'createRenew',
        'activateRenew',
        'deleteClient',
        'deleteInvoice',
        'restoreInvoice',
        'news',
        'changeLicenseRenewalStatus',
      ].includes(action)
    case 'commercial':
      return [
        'createClient',
        'changeClientInformation',
        'changeUserStatus',
        'assignCommercial',
        'createInvoice',
        'changeInvoiceStatus',
        'createRenew',
        'deleteInvoice',
        'changeLicenseRenewalStatus',
      ].includes(action)
    case 'billing':
      return ['changeUserStatus', 'changeInvoiceStatus'].includes(action)
    case 'support':
      return [
        'changeUserStatus',
        'changeClientInformation',
        'activateInvoice',
        'activateRenew',
      ].includes(action)
    case 'marketing':
      return ['news'].includes(action)

    default:
      return false
  }
}

/**
 * Gets the users stored in an object where the key is the id and the value is the document.
 * @returns Users of the management app but stored in an object.
 */
export async function getUsersDict() {
  const docsSnap = await Firestore.getDocs(UsersManagementCollection)

  const users = {}

  docsSnap.docs.forEach((doc) => {
    users[doc.id] = { id: doc.id, ...doc.data() }
  })

  return users
}

/**
 * Guarda una marca personalizada para un usuario.
 *
 * @param {string} userId - El ID del usuario.
 * @param {string} customBrand - La marca personalizada a guardar.
 * @returns {Promise<void>} Una promesa que se resuelve cuando se guarda la marca personalizada.
 */
export async function saveCustomBrand(userId, customBrand) {
  try {
    const response = await Firestore.updateDoc(UsersManagementDoc(userId), {
      customBrand: customBrand ? customBrand : Firestore.deleteField(),
    })
    return response
  } catch (error) {
    console.log('Error in saveCustomBrand', error)
    throw error
  }
}
