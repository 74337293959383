<template>
  <v-card elevation="0">
    <v-card-title :class="isMobile ? 'ma-0 pa-0' : ''">
      <v-container v-if="allowToPerformAction('createClient')" fluid>
        <v-row
          no-gutters
          align="center"
          justify="space-between"
          v-if="!isMobile"
        >
          <v-col cols="4" no-gutters>
            <v-row align="baseline">
              <span class="my-0 mx-2">
                {{ $t('tabTitle.clients') }}
              </span>
              <DialogButton
                v-if="false"
                section="clientList"
                hideButton
                hideSubtitle
              />
            </v-row>
          </v-col>
          <v-col cols="auto">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  elevation="1"
                  fab
                  small
                  class="ma-1"
                  v-bind="attrs"
                  v-on="on"
                  id="open-create-client-form"
                  @click.stop="openCreateClientForm"
                >
                  <v-icon> mdi-account-plus-outline </v-icon>
                </v-btn>
              </template>
              <div>
                {{ $t('tabTitle.clientCreate') }}
              </div>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-col v-else>
          <v-row class="mb-1" align="baseline">
            <span class="my-0 mx-2">
              {{ $t('tabTitle.clients') }}
            </span>
          </v-row>
          <v-row class="mt-3 d-flex justify-center">
            <v-col align="center">
              <v-btn
                elevation="1"
                fab
                small
                class="primary secondary--text ma-1"
                @click.stop="openClientDetail"
              >
                <v-icon> mdi-account-plus-outline </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-container>
      <v-spacer></v-spacer>
    </v-card-title>
    <AppSkeleton data-v-step="201" :condition="loading" type="clientList">
      <template v-slot:skeleton-content>
        <v-data-table
          id="client-list"
          :loading="loadingData"
          :headers="headers"
          :items="filteredClients"
          :items-per-page="25"
          class="elevation-1 custom-table"
          @click:row="openClientDetail"
          :footer-props="{
            'items-per-page-options': [5, 25, 50, 100 /*-1*/],
          }"
          :options="options"
          @update:options="optionsChange"
        >
          <template v-slot:top>
            <v-text-field
              v-model="search"
              :label="$t('client.clientList.searchClient')"
              @change="searchChange"
              clearable
              class="mx-4"
            ></v-text-field>
          </template>
          <template v-slot:[`item.estado`]="{ item }">
            <td class="custom-padding">
              <v-container class="px-0">
                <v-row>
                  <v-chip
                    class="ma-2 white--text"
                    :color="getClientStatus(item).color"
                    id="enter-notif-detail"
                  >
                    {{ $t(getClientStatus(item).status) }}
                  </v-chip>
                  <v-chip
                    v-if="item.trialLicense"
                    class="ma-2 mx-0 white--text"
                    color="orange"
                  >
                    {{ $t('trialLicenseChip') }}
                  </v-chip>
                  <v-chip
                    v-if="item.estadoRenovacionLicencia"
                    class="ma-2 mx-0 white--text"
                    :color="getClientRenewalStatus(item).color"
                  >
                    {{ $t(getClientRenewalStatus(item).status) }}
                  </v-chip>
                </v-row>
              </v-container>
            </td>
          </template>
          <template v-slot:[`item.firmasActivas`]="{ item }">
            <v-icon v-if="item.firmasActivas"> mdi-check </v-icon>
            <v-icon v-else> mdi-close </v-icon>
          </template>
          <template v-slot:[`item.notificacionesActivas`]="{ item }">
            <v-icon v-if="item.notificacionesActivas"> mdi-check </v-icon>
            <v-icon v-else> mdi-close </v-icon>
          </template>
          <template v-slot:[`item.commercial`]="{ item }">
            {{
              isAddaliaUser && item.channel
                ? item.channel.name
                : item.commercial?.name
            }}
          </template>
          <!--- <template v-slot:[`item.channel`]="{ item }">
            {{ item.channel?.name }}
          </template> -->
        </v-data-table>
      </template>
    </AppSkeleton>
  </v-card>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import DialogButton from '@/components/documentation/buttons/DialogButton'
import { getClientStatus } from '@/services/clients-service'
import { getClientRenewalStatus } from '@/services/clients-service'
import { allowToPerformAction } from '@/services/users-service'

export default {
  props: {
    clients: Array,
    loading: Boolean,
  },
  components: {
    DialogButton,
  },
  data() {
    return {
      search: '',
      loadingData: false,
      options: {},
    }
  },
  created() {
    this.options = this.$store.state.clientsListOptions
    this.search = this.$store.state.clientsSearch
  },
  computed: {
    ...mapGetters(['isMobile', 'isAddaliaUser']),
    filteredClients() {
      return this.clients.filter((item) => {
        // String de búsqueda en mayúscula
        const searchCap = this.search ? this.search.toUpperCase() : ''

        // Obtenemos los elementos del cliente
        const {
          id,
          tipoDocIdentidadEmpresa,
          numeroDocIdentidadEmpresa,
          empresa,
          name,
          tipoEmpresa,
          email,
          //telefono,
          commercial,
          channel,
        } = item

        // Guardamos los elementos en mayúscula
        let dataCap = [
          this.$t(getClientStatus(item).status).toUpperCase(),
          item.trialLicense
            ? this.$t('trialLicenseChip').toUpperCase() + 'Trial'.toUpperCase()
            : '',
          item.estadoRenovacionLicencia
            ? this.$t(getClientRenewalStatus(item).status).toUpperCase()
            : '',
          id?.toUpperCase(),
          tipoDocIdentidadEmpresa?.toUpperCase(),
          numeroDocIdentidadEmpresa?.toUpperCase(),
          empresa?.toUpperCase(),
          name?.toUpperCase(),
          tipoEmpresa?.toUpperCase(),
          email?.toUpperCase(),
          //telefono?.toUpperCase(),
          commercial?.name?.toUpperCase(),
          channel?.name?.toUpperCase(),
        ]

        // Filtramos
        for (let i = 0; i < dataCap.length; i++)
          if (dataCap[i]?.includes(searchCap)) return item
      })
    },
    headers() {
      return [
        {
          text: this.$t('client.clientList.numberDocID'),
          value: 'numeroDocIdentidadEmpresa',
        },
        {
          text: this.$t('client.clientList.company'),
          value: 'empresa',
        },
        { text: this.$t('client.clientList.contactName'), value: 'name' },
        { text: this.$t('client.clientList.type'), value: 'tipoEmpresa' },
        { text: this.$t('client.clientList.status'), value: 'estado' },
        { text: this.$t('client.clientList.email'), value: 'email' },
        //{ text: this.$t('client.clientList.phone'), value: 'telefono' },
        {
          text: this.$t('client.clientList.notifications'),
          value: 'notificacionesActivas',
        },
        {
          text: this.$t('client.clientList.signatures'),
          value: 'firmasActivas',
        },
        {
          text: this.$t(
            `client.clientList.${
              this.isAddaliaUser ? 'channelOrCommercial' : 'commercial'
            }`
          ),
          value: 'commercial',
          sortable: false,
        },
        /*{
          text: this.$t('client.clientList.channel'),
          value: 'channel',
        },*/
      ]
    },
  },
  methods: {
    ...mapMutations(['setClientsListOptions', 'setClientsSearch']),
    openClientDetail(client) {
      this.$router.push({
        name: 'ClientDetailView',
        params: { id: client.id, client: client },
      })
    },
    async openCreateClientForm() {
      this.$router.push({
        name: 'CreateClientView',
      })
    },
    getClientStatus(client) {
      return getClientStatus(client)
    },
    getClientRenewalStatus(client) {
      return getClientRenewalStatus(client)
    },
    optionsChange(options) {
      this.setClientsListOptions(options)
    },
    searchChange(search) {
      this.setClientsSearch(search ? search : '')
    },
    allowToPerformAction(action) {
      return allowToPerformAction(this.$store.state.user.rol, action)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/global/global-styles.css';

::v-deep .custom-padding {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
</style>
