import { render, staticRenderFns } from "./CuotaLicenses.vue?vue&type=template&id=53a3086f&scoped=true"
import script from "./CuotaLicenses.vue?vue&type=script&lang=js"
export * from "./CuotaLicenses.vue?vue&type=script&lang=js"
import style0 from "./CuotaLicenses.vue?vue&type=style&index=0&id=53a3086f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53a3086f",
  null
  
)

export default component.exports