const mutations = {
  setUser(state, user) {
    state.user = user
  },
  setLoading(state, value) {
    state.loading = value
  },
  setNotFound(state, notFound) {
    state.notFound = notFound
  },
  setSnackbar(
    state,
    { isDisplayed = true, position, message, type, timeout = 5000 }
  ) {
    const snackbar = { isDisplayed, position, message, type, timeout }
    state.snackbar = snackbar
  },
  setNavigationPages(state, navigationPages) {
    state.navigationPages = navigationPages
  },
  setClients(state, clients) {
    state.clients = clients
  },
  setClientsListOptions(state, options) {
    state.clientsListOptions = options
  },
  setClientsSearch(state, search) {
    state.clientsSearch = search
  },
  setClient(state, client) {
    state.client = client
  },
  setCurrentClient(state, client) {
    state.client = client
  },
  setClientsUnsubscribe(state, method) {
    state.clientsUnsubscribe = method
  },
  setClientUnsubscribe(state, method) {
    state.clientUnsubscribe = method
  },
  setUsersListOptions(state, options) {
    state.usersListOptions = options
  },
  setUsersSearch(state, search) {
    state.usersSearch = search
  },
  setNews(state, news) {
    state.news = news
  },
  setHistory(state, history) {
    state.history = history
  },
  setNewsUnsubscribe(state, method) {
    state.newsUnsubscribe = method
  },
  killListenersMutation(state) {
    // Matamos el listener de las notificaciones
    if (state.clientsUnsubscribe) state.clientsUnsubscribe()
    if (state.usersUnsubscribe) state.usersUnsubscribe()
    if (state.newsUnsubscribe) state.newsUnsubscribe()
  },
  resetState(state) {
    state.notFound = false
    state.user = null
    state.snackbar = {}
    state.loading = false
    state.clients = null
    state.clientsUnsubscribe = () => {}
    state.news = []
    state.newsUnsubscribe = () => {}
    state.appBilling = {
      // Paquetes EMPRESA
      empresaAppCuota: 0,
      empresaNotificacionesCif: 0,
      empresaNotificacionesPaquetesCuota: [],
      empresaNotificacionesPaquetes: [], //para simplificar el algoritmo de restarNumerosHastaMenor
      empresaFirmasCertificadosCuota: [],
      empresaFirmasCertificados: [], //para simplificar el algoritmo de restarNumerosHastaMenor
      empresaFirmasPaquetesCuota: [],
      empresaFirmasPaquetes: [], //para simplificar el algoritmo de restarNumerosHastaMenor
      empresa2FACuota: 0,
      empresaBrandCuota: 0,
      //Paquetes DESPACHO
      despachoAppCuota: 0,
      despachoNotificacionesCif: 0,
      despachoNotificacionesPaquetesCuota: [],
      despachoNotificacionesPaquetes: [], //para simplificar el algoritmo de restarNumerosHastaMenor
      despachoFirmasCertificadosCuota: [],
      despachoFirmasCertificados: [], //para simplificar el algoritmo de restarNumerosHastaMenor
      despachoFirmasPaquetesCuota: [],
      despachoFirmasPaquetes: [], //para simplificar el algoritmo de restarNumerosHastaMenor
      despacho2FACuota: 0,
      despachoBrandCuota: 0,
      // Paquetes Tokens MAYORISTA
      mayoristaTokenPaquetesCuota: [],
      mayoristaTokenPaquetes: [], //para simplificar el algoritmo de restarNumerosHastaMenor
      // Lista de correo de Gestión
      aManagementMailingList: '',
      // numero de licencias usadas por cada CIF gran destinatario de un Despacho
      consumoLicenciasCifGD: 0,
    }
    state.appLegal = {}
  },
  setAppBilling(state, billing) {
    state.appBilling = billing
  },
  setAppLegal(state, legal) {
    state.appLegal = legal
  },
}

export default mutations
